<div class="access-container">
  <h1>
    3. Zugangscodes herunterladen und an die benannten Personen übermitteln
  </h1>
  <ng-container>
    <app-note kind="danger">
      <p>
        Bitte schließen Sie <strong>ALLE</strong> Schritte ab (Zugangscodes
        herunterladen, Absenden der Daten) bevor Sie das Fenster schließen.
        Ansonsten werden die
        <strong>Daten nicht gespeichert und gehen verloren!</strong>
      </p>
    </app-note>
  </ng-container>
  <p>
    Bitte übermitteln Sie die persönlichen Anschreiben mit den Zugangscodes an
    die von Ihnen benannten Personen. Nach Freigabe durch das BfArM wird eine
    E-Mail mit dem Registrierunglink versendet. Der Zugangscode wird für die
    Registrierung benötigt.
  </p>
  <p>
    Sollten Sie sich selbst benannt haben, speichern Sie sich das PDF mit dem
    Code an einem sicheren Ort bis Sie die Bestätigungs-E-Mail vom BfArM
    erhalten.
  </p>
  <img
    class="illustrations"
    alt=""
    src="./assets/images/logos/HandLetter.png"
  />
  <ol>
    <li>
      <p class="inside-list">
        <strong>
          Dokumente ausdrucken und die Seite für jede benannte Person in einen
          separaten Umschlag legen.
        </strong>
      </p>
    </li>
    <li>
      <p class="inside-list">
        <strong> Dokumente sicher (z.B. persönlich) übergeben </strong>
      </p>
    </li>
  </ol>
  <div class="codes-button-container">
    <ogr-button
      class="apkv-code-margin-btn btn-margin-right"
      [color]="
        (namingProcessData$ | async)?.zugangDownloaded ? 'secondary' : 'primary'
      "
      (buttonClick)="handleSubmit()"
      buttonId="get-access-code-pdf-button"
    >
      {{ getButtonLabel((namingProcessData$ | async)?.zugangDownloaded) }}
    </ogr-button>
    <app-navigation-next-button
      [isNextDisabled]="!(namingProcessData$ | async)?.zugangDownloaded"
    ></app-navigation-next-button>
    <div
      class="access-code-spinning-container"
      *ngIf="(loading$ | async)?.downloadzugang"
    >
      <app-spinner text="PDF wird heruntergeladen"></app-spinner>
    </div>
  </div>
  <div class="apkv-nav-end-button-container">
    <app-navigation-previous-button></app-navigation-previous-button>
  </div>
</div>
