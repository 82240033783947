export enum SiteState {
  LOGGED_IN,
  LOGGED_OUT,
}

export interface InfoPage {
  label: string;
  link: ((currentUrl: string) => string) | string;
  isAbsolute: boolean;
  requiredStates: SiteState[];
}
