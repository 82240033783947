import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from './apkv.actions';
import { FeedbackService } from '../../shared';
import { UtilsService } from '../../shared';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApkvStore } from '../apkv.store';
import { ApkvState, selectApkvState } from './apkv.selectors';
import { PdfResponseModel } from 'src/app.model';
import { ApkvService } from '../apkv.service';

@Injectable()
export class ApkvEffects {
  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService,
    private utilsService: UtilsService,
    private apkvStore: ApkvStore,
    private store: Store,
    private router: Router,
    private apkvService: ApkvService,
  ) {}

  checkCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkCode),
      switchMap((action) => this.ueberpruefeZugangsdaten(action)),
    ),
  );

  checkNamingprocessStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.retrieveApkvList),
      switchMap((action) => this.rufeAPKVsAb(action)),
    ),
  );

  deactiveApkv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deactiveApkv),
      withLatestFrom(this.store.select(selectApkvState)),
      switchMap(([, state]) => this.deaktivereAPKV(state)),
    ),
  );

  checkApkvEmailsInUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkApkvEmailsInUserAccount),
      withLatestFrom(this.store.select(selectApkvState)),
      switchMap(([action]) => this.checkApkvEmailsInUserAccount(action)),
    ),
  );

  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.downloadPdf),
      withLatestFrom(this.store.select(selectApkvState)),
      switchMap(([action, state]) => this.downloadPdf(action, state)),
    ),
  );

  createApkv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createApkv),
      withLatestFrom(this.store.select(selectApkvState)),
      switchMap(([, state]) => this.erstelleAPKVs(state)),
    ),
  );

  refreshSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.refreshSession),
        switchMap(() => this.apkvStore.refreshSession()),
      ),
    { dispatch: false },
  );

  destroySession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.destroySession),
        switchMap(() => this.apkvStore.destroySession()),
      ),
    { dispatch: false },
  );

  /**
   * Überprüfen ob die eMails bereits existiert
   *
   * @param action
   * @private
   */
  private checkApkvEmailsInUserAccount(action: any): Observable<any> {
    return this.apkvStore.checkApkvEmail(action.personObject).pipe(
      map((personenFormValidationObject) => {
        let isValid = true;
        const reducedFormValidationObject = Object.keys(
          personenFormValidationObject,
        ).reduce((acc, curr) => {
          if (
            !personenFormValidationObject[curr] ||
            personenFormValidationObject[curr] === ''
          ) {
            return acc;
          }
          return { ...acc, [curr]: personenFormValidationObject[curr] };
        }, {});
        for (const key of Object.keys(reducedFormValidationObject)) {
          if (
            personenFormValidationObject[key] &&
            personenFormValidationObject[key] !== 'GUELTIG'
          ) {
            isValid = false;
          }
        }
        if (isValid) {
          this.store.dispatch(actions.setNextNavigationIndex());
          this.store.dispatch(
            actions.setNamingProcessApkvData({ apkvData: action.apkvObject }),
          );
        }
        return {
          type: actions.apkvActionTypes
            .CHECK_APKV_EMAILS_IN_USER_ACCOUNT_SUCCESS,
          personenFormValidationObject,
        };
      }),
      catchError((error) => {
        this.feedbackService.showError(
          'Die von Ihnen gesendeten APK-V E-Mail-Adressen konnten nicht geprüft werden.',
          error,
        );
        console.error(error);
        return of({
          type: actions.apkvActionTypes
            .CHECK_APKV_EMAILS_IN_USER_ACCOUNT_FAILED,
          error,
        });
      }),
    );
  }

  /**
   * APKV Deaktivieren
   *
   * @param state
   * @private
   */
  private deaktivereAPKV(state: ApkvState) {
    return this.apkvStore.deactivateApkv(state.activeApkv.email).pipe(
      map((result) => {
        this.feedbackService.showFeedback({
          type: 'success',
          message:
            'Die von Ihnen übermittelte Person konnte erfolgreich deaktiviert werden.',
        });
        return {
          type: actions.apkvActionTypes.DEACTIVE_APKV_SUCCESS,
          ekhData: result,
        };
      }),
      catchError((error) => {
        if (error.includes('409')) {
          this.feedbackService.showError(
            `Es muss mindestens eine APK-V aktiv sein. Bitte legen Sie zunächst eine neue Person an.
                               Nachdem sich die neue APK-V erfolgreich registriert hat können Sie die bestehende APK-V deaktivieren.`,
            error,
          );
        } else {
          this.feedbackService.showError(
            'Die APKV konnten nicht deaktiviert werden.',
            error,
          );
        }
        console.error(error);
        return of({
          type: actions.apkvActionTypes.DEACTIVE_APKV_FAILED,
          error,
        });
      }),
    );
  }

  /**
   * PDF Dokument herunterladen
   *
   * @param action
   * @param state
   * @private
   */
  private downloadPdf(action: any, state: ApkvState) {
    return this.apkvStore
      .generatePdfByType(
        action.pdfType,
        state.namingProcessData.apkvData,
        action.apkv,
      )
      .pipe(
        map((result) => {
          const { fileName } = this.utilsService.getPdfObjectByType(
            action.pdfType,
          );
          const pdfmodel = result as PdfResponseModel;
          const linkSource =
            'data:application/pdf;base64,' + pdfmodel.pdfcontent;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          return {
            type: actions.apkvActionTypes.DOWNLOAD_PDF_SUCCESS,
            pdfType: action.pdfType,
          };
        }),
        catchError((error) => {
          const message =
            'Das angeforderte PDF konnte nicht heruntergeladen werden.';
          this.feedbackService.showError(message, error);
          console.error(message, error);
          return of({
            type: actions.apkvActionTypes.DOWNLOAD_PDF_FAILED,
            error,
            pdfType: action.pdfType,
          });
        }),
      );
  }

  private erstelleAPKVs(state: ApkvState) {
    return this.apkvStore.createApkv(state.namingProcessData.apkvData).pipe(
      map(() => {
        const apkvData = this.apkvService.getApkvDataArrayByObject(
          state.namingProcessData.apkvData,
        );
        const updatedApkvData = apkvData.map((apkv) => {
          return {
            ...apkv,
            status: 'BENANNT',
            statusDatum: this.utilsService.getCurrentDate(),
          };
        });
        const message =
          apkvData?.length > 1
            ? `Die von Ihnen benannten Personen
                            zur Benennung von abrufberechtigten Personen
                            wurden an das Bundesinstitut für Arzneimittel
                            und Medizinprodukte übermittelt.`
            : `Die von Ihnen benannte Person
                            zur Benennung von abrufberechtigten Personen
                            wurden an das Bundesinstitut für Arzneimittel
                            und Medizinprodukte übermittelt.`;
        this.feedbackService.showFeedback({
          type: 'success',
          message,
        });
        this.router.navigate(['/apkv/thank-you']);
        return {
          type: actions.apkvActionTypes.CREATE_APKV_SUCCESS,
          apkvData: updatedApkvData,
        };
      }),
      catchError((error) => {
        const message = 'Die APKV Daten konnten nicht übermittelt werden.';
        this.feedbackService.showError(message, error);
        console.error(error);
        return of({ type: actions.apkvActionTypes.CREATE_APKV_FAILED, error });
      }),
    );
  }

  private rufeAPKVsAb(action: any): Observable<any> {
    return this.apkvStore.getApkvData(action.sessionId).pipe(
      map((result) => {
        if (result.apkvs.length > 0) {
          this.router.navigate([`/apkv/overview`]);
        } else {
          this.router.navigate([`/apkv/naming`]);
        }
        return {
          type: actions.apkvActionTypes.RETRIEVE_APKVS_SUCCESS,
          ekhData: result,
        };
      }),
      catchError((error) => {
        this.feedbackService.showError(
          `Es ist ein Fehler im EKH-Portal aufgetreten.
                          Bitte versuchen Sie es später erneut.`,
          error,
        );
        console.error(error);
        return of({
          type: actions.apkvActionTypes.RETRIEVE_APKVS_FAILED,
          error,
        });
      }),
    );
  }

  /**
   * Zugangsdaten ans Backend schicken und überprüfen
   *
   * @param action
   * @private
   */
  private ueberpruefeZugangsdaten(action: any): Observable<any> {
    return this.apkvStore.checkCode(action.zugangscode, action.pin).pipe(
      map((result) => {
        this.store.dispatch(actions.retrieveApkvList({ sessionId: result }));
        return {
          type: actions.apkvActionTypes.CHECK_CODE_SUCCESS,
          sessionId: result,
        };
      }),
      catchError((error) => {
        let errorText = 'Bitte geben Sie einen gültigen Zugangscode ein.';
        if (error.includes('500') || error.includes('502')) {
          this.feedbackService.showError(
            'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
            error,
          );
          return of({
            type: actions.apkvActionTypes.CHECK_CODE_FAILED,
            error,
          });
        }
        if (error.includes('400')) {
          errorText =
            'PIN oder Zugangscode sind nicht valide, bitte prüfen Sie Ihre Angabe. Sollte das Problem weiterhin bestehen, wenden Sie sich an das Bundesinstitut für Arzneimittel und Medizinprodukte (<a class="anchor-class" href="mailto:ekh@organspende-register.de" target="_blank" title="BfArM E-Mail">ekh@organspende-register.de</a>)';
        }
        console.error(errorText, error);
        return of({
          type: actions.apkvActionTypes.CHECK_CODE_FAILED,
          error,
          codeValidityObject: { codeAndPinAreInvalid: errorText },
        });
      }),
    );
  }
}
