import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from 'src/app/footer/footer.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableComponent } from './components/table-generator/table-generator.component';
import { FormsModule } from '@angular/forms';
import { WizardNavigationComponent } from './components/wizard-navigation/wizard-navigation.component';
import { NoteComponent } from './components/note/note.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { StatusComponent } from './components/status/status.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { ErrorStatusPageComponent } from './components/error-status-page/error-status-page.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { PopupComponent } from './components/popup/popup.component';
import { ButtonComponent } from './components/button/button.component';
import { IconComponent } from './components/icon/icon.component';
import { A11yModule } from '@angular/cdk/a11y';
import { AutoTabDirective } from './directives/auto-tab.directive';

const components = [
  ButtonComponent,
  IconComponent,
  FooterComponent,
  FeedbackComponent,
  SpinnerComponent,
  TableComponent,
  WizardNavigationComponent,
  NoteComponent,
  ErrorMessageComponent,
  StatusComponent,
  CountdownComponent,
  PopupComponent,
  PageNotFoundComponent,
  ErrorStatusPageComponent,
  LinkButtonComponent,
  AutoTabDirective,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, RouterModule, A11yModule],
  exports: [...components],
})
export class SharedCommonModule {}
