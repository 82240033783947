import { Component, Input } from '@angular/core';
import { WizardConfig } from './wizard-navigation.model';

@Component({
  selector: 'app-wizard-navigation',
  templateUrl: './wizard-navigation.component.html',
  styleUrls: ['./wizard-navigation.component.scss'],
})
export class WizardNavigationComponent {
  @Input() wizardItems: Pick<WizardConfig, 'label' | 'routeFragment'>[] = []; // Array of labels-string that has to be displayed.
  @Input() currentNavIndex = 0; // Current/Active position of the Navigation list.

  getNavClass(i: number): Record<string, boolean | undefined | null> {
    const classes: Record<string, boolean> = {
      selected: false,
      completed: false,
    };
    if (i === this.currentNavIndex) {
      classes.selected = true;
    }
    if (i < this.currentNavIndex) {
      classes.completed = true;
    }

    return classes;
  }

  getAriaCurrentValue(i: number): string | null {
    if (i === this.currentNavIndex) {
      return 'step';
    }
    return null;
  }
}
