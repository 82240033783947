import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApkvService } from '../overview/apkv.service';
import { UtilsService } from '../shared';
import { Store } from '@ngrx/store';
import { selectSessionId } from '../overview/+store/apkv.selectors';
import dependencyWrapper from './apkvportal-frontend.json';
@Component({
  selector: 'app-rechtliche-hinweise',
  templateUrl: './rechtliche-hinweise.component.html',
  styleUrls: ['./rechtliche-hinweise.component.scss'],
})
export class RechtlicheHinweiseComponent {
  loggedIn = false;

  dependencies = dependencyWrapper.dependencies;

  constructor(
    private utilsService: UtilsService,
    private store: Store,
    private router: Router,
    private apkvService: ApkvService,
  ) {}

  ngOnInit(): void {
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    this.loggedIn = sessionId !== null && sessionId !== undefined;
  }

  public goBackToForm(): void {
    this.router.navigate([this.apkvService?.url || '/']);
  }
}
