<div *ngIf='(apkvList$ | async) as liste'>
  <span [attr.aria-live]='"polite"' [id]='"aria-tabledescription"' class='screen-reader-only'>
      {{getAriaLiveDescription(liste)}}
  </span>
  <table [id]='"table-" + entity' [attr.aria-describedby]='"aria-tabledescription"' class='content-table'>
    <thead id='table-header'>
    <tr>
      <ng-container *ngFor='let headerRow of tableConfig'>
        <th *ngIf='headerRow.type !== "checkbox"' [id]='"tabellenkopf_" + headerRow.key'
            [attr.colspan]='headerRow.columnSize' [ngClass]="headerRow.cssClass">
          {{headerRow.title}}
        </th>
      </ng-container>
    </tr>
    </thead>
    <tbody *ngIf='!(loading$ | async).apkvList'>
    <tr *ngFor='let listItem of liste; let listIndex=index;'>
      <ng-container *ngFor='let row of tableConfig; let i=index;'>
        <!-- deactive name -->
        <td [attr.colspan]='row.columnSize' class='deactive-name' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "disabled", listItem.status)'>
          <p *ngIf='row.key === "fullname"'>
            <strong>
              {{utilsService.getReverseSeperatedFullName({
              vorname: listItem.vorname,
              nachname: listItem.nachname,
              titel: listItem.titel
            })}}</strong>
          </p>
          <p *ngIf='row.key !== "fullname"'>{{listItem[row.key]}}</p>
        </td>
        <!-- row Type string => normal p-Element -->
        <td [attr.colspan]='row.columnSize' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "string", listItem.status)'>
          <p>{{getLabelByValue(listItem[row.key])}}</p>
        </td>
        <!-- row Type fullname => Full Name of Person -->
        <td [attr.colspan]='row.columnSize' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "fullName", listItem.status)'>
          <strong>{{utilsService.getReverseSeperatedFullName({
            vorname: listItem.vorname,
            nachname: listItem.nachname,
            titel: listItem.titel
          })}}</strong>
        </td>
        <!-- row Type bold => strong-Element -->
        <td [attr.colspan]='row.columnSize' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "bold", listItem.status)'>
          <strong>{{listItem[row.key]}}</strong>
        </td>
        <!-- row Type state => state card custom component -->
        <td [attr.colspan]='row.columnSize' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "state", listItem.status)'>
          <app-status [stateList]='stateList' [state]='listItem[row.key]'></app-status>
        </td>
        <!-- row Type action => action custom component -->
        <td [attr.colspan]='row.columnSize' [id]='getCellId(row.key, listIndex)'
            *ngIf='shouldRenderTableCell(row, "action", listItem.status)'>
            <span class="flex-row" *ngIf='!(loading$ | async).downloaderneuereRegistrierungscode'>
              <ogr-button *ngIf='[apkvStates.FREIGEGEBEN.toString(),
              apkvStates.AKTIV.toString()].includes(listItem.status)' title='Apkv deaktivieren'
                          [buttonId]="'delete-button-'+listIndex" color='secondary'
                          (buttonClick)='handleDisable(listItem)'>
                Deaktivieren
              </ogr-button>
              <span class='freigabeantag-cell-container' *ngIf='listItem.status === apkvStates.BENANNT.toString()'>
                <!--HTMl kurz und sauber halten, durch ein leeres alt wird das bild nicht vorgelesen und es wird kein weiteres aria-hidden gebraucht-->
                <img id='clock-icon' alt='' src='assets/icons/clock.svg'>
                <p>Freigabeantrag wird geprüft</p>
              </span>
              <button *ngIf="[apkvStates.BENANNT.toString(),
              apkvStates.FREIGEGEBEN.toString()].includes(listItem.status)"
                      (click)="handleOpenNewCodeDialog(listItem)" class="new-code-btn"
                      [id]="'new-code-button-' + listIndex" aria-label="Neuer Registrierungscode"
                      title='Neuer Registrierungscode'>
              <ogr-icon icon="more" height="35" width="35"></ogr-icon>
            </button>
            </span>
          <span #spinnerBlock class='spinning-container action-spinner-container'
                *ngIf='(loading$ | async).downloaderneuereRegistrierungscode && isActiveApkv(listItem)'>
              <app-spinner text='PDF wird geladen'></app-spinner>
            </span>
          <span *ngIf='!apkvStatesArray.includes(listItem.status)'></span>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
  <div class='spinning-container' *ngIf='(loading$ | async).apkvList'>
    <app-spinner text='Liste wird geladen'></app-spinner>
  </div>
</div>
