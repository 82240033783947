import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FeedbackService } from 'src/app/shared/components/feedback/feedback.service';
import { checkCode } from 'src/app/overview/+store/apkv.actions';
import { Store } from '@ngrx/store';
import { selectLoading } from 'src/app/overview/+store/apkv.selectors';
import { resetCodeValidityObject } from '../../../../+store/apkv.actions';
import { selectCodeValidityObject } from '../../../../+store/apkv.selectors';
import { accessCodeValidator } from 'src/app/shared';

const ACCESS_CODE_VALIDATORS = [Validators.required];

@Component({
  selector: 'app-apkv-check-code',
  templateUrl: './apkv-check-code.component.html',
  styleUrls: ['./apkv-check-code.component.scss'],
})
export class ApkvCheckCodeComponent implements OnDestroy {
  @ViewChild('firstCodeInput') codeOneInput: ElementRef;
  @ViewChild('secondCodeInput') codeTwoInput: ElementRef;
  @ViewChild('thirdCodeInput') codeThreeInput: ElementRef;
  @ViewChild('fourthCodeInput') codeFourInput: ElementRef;
  @ViewChild('pinInput') pinInput: ElementRef;
  public readonly errorMessage = 'Bitte Pflichtfeld ausfüllen.';
  public submitted = false;
  public checkCodeForm = new FormGroup({
    code: new FormGroup(
      {
        code1: new FormControl('', ACCESS_CODE_VALIDATORS),
        code2: new FormControl('', ACCESS_CODE_VALIDATORS),
        code3: new FormControl('', ACCESS_CODE_VALIDATORS),
        code4: new FormControl('', ACCESS_CODE_VALIDATORS),
      },
      {
        validators: accessCodeValidator,
      },
    ),
    pin: new FormControl('', Validators.required),
  });
  private codeStatus$ = this.store.select(selectCodeValidityObject);
  public loading$ = this.store.select(selectLoading);

  public codeOrPinAreInvalid = false;
  public codeOrPinAreInvalidText;

  public get codeFormGroup(): FormGroup {
    return this.checkCodeForm.controls.code;
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text').trim();
    let pastedCode = pastedText?.split(' ');
    if (pastedCode?.length === 1 && pastedText?.length === 16) {
      pastedCode = pastedText.match(/.{1,4}/g);
    }
    if (pastedCode?.length === 4) {
      event.preventDefault();
      this.checkCodeForm.get('code').get('code1').setValue(pastedCode[0]);
      this.checkCodeForm.get('code').get('code2').setValue(pastedCode[1]);
      this.checkCodeForm.get('code').get('code3').setValue(pastedCode[2]);
      this.checkCodeForm.get('code').get('code4').setValue(pastedCode[3]);
    }
  }

  public get pinControl(): FormControl {
    return this.checkCodeForm.controls.pin;
  }

  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly store: Store,
  ) {}

  public isFormControlInvalid(control: AbstractControl) {
    // Fehler erst nach Submit anzeigen
    return (this.submitted && control.invalid) || this.codeOrPinAreInvalid;
  }

  public handleSubmit(): void {
    this.submitted = true;

    if (this.checkCodeForm.valid) {
      const zugangscode = this.checkCodeForm?.controls?.code.value.code1.concat(
        this.checkCodeForm?.controls?.code.value.code2,
        this.checkCodeForm?.controls?.code.value.code3,
        this.checkCodeForm?.controls?.code.value.code4,
      );
      const pin = this.checkCodeForm?.value?.pin;

      this.store.dispatch(checkCode({ zugangscode, pin }));
      this.codeStatus$.subscribe((result) => {
        if (result?.codeAndPinAreInvalid) {
          this.codeOrPinAreInvalid = true;
          this.codeOrPinAreInvalidText = result.codeAndPinAreInvalid;
          // focus the first one
          this.codeOneInput.nativeElement.focus();
        } else {
          this.submitted = false;
        }
      });
    }
    this.setFocusToTheFirstInvalidInputField();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(resetCodeValidityObject());
    this.feedbackService.resetFeedbackObject();
  }

  public getErrorCodeHint(controlName: string): string {
    if (this.checkCodeForm.get('code').get(controlName).invalid) {
      return 'error-code-message';
    }
    return this.codeOrPinAreInvalid ? 'error-invalid-message' : '';
  }

  private setFocusToTheFirstInvalidInputField(): void {
    if (this.checkCodeForm.get('pin').invalid) {
      this.pinInput.nativeElement.focus();
    } else {
      if (this.codeFormGroup.get('code1').invalid) {
        this.codeOneInput.nativeElement.focus();
      } else if (this.codeFormGroup.get('code2').invalid) {
        this.codeTwoInput.nativeElement.focus();
      } else if (this.codeFormGroup.get('code3').invalid) {
        this.codeThreeInput.nativeElement.focus();
      } else if (this.codeFormGroup.get('code4').invalid) {
        this.codeFourInput.nativeElement.focus();
      }
    }
  }
}
