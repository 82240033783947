import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Feedback } from './feedback.model';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedback$: Observable<Feedback>;
  feedbackIcon: string;
  feedbackContainerClass: string;

  constructor(public feedbackService: FeedbackService) {}
  onClose(): void {
    this.feedbackService.resetFeedbackObject();
  }

  handleFeedbackClasses(type): {
    feedbackIcon?: string;
    feedbackContainerClass?: string;
  } {
    if (!type) {
      return {};
    }
    const feedbackIcon = type === 'error' ? 'alarm' : 'checkmark_icon';
    const feedbackContainerClass = `feedback-container ${
      type === 'error' ? type : 'success'
    }-feedback-container`;
    return { feedbackIcon, feedbackContainerClass };
  }

  ngOnInit(): void {
    this.feedback$ = this.feedbackService.feedback$.pipe(
      tap((res) => {
        const { feedbackIcon, feedbackContainerClass } =
          this.handleFeedbackClasses(res?.type);
        this.feedbackIcon = feedbackIcon;
        this.feedbackContainerClass = feedbackContainerClass;
      }),
    );
  }
}
