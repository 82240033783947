<div class='infopage-container container-layout'>
    <div class='infopage-content content-layout'>
        <div class='top-bar-content btn-margin-button' *ngIf='!loggedIn'>
            <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left' >
              <span>Zurück</span>
            </ogr-button>
          </div>
        <h1>Impressum</h1>
        <p>Das Register für Erklärungen zur Organ- und Gewebespende wird herausgegeben von</p>
        <div class='infopage-content-block'>
            <h2>Bundesinstitut für Arzneimittel und Medizinprodukte (BfArM)</h2>
            <div>
                <div class="infopage-content-block">
                    <strong class="no-padding">
                        Dienstsitz Bonn:
                    </strong>
                    <p class="no-padding">Kurt-Georg-Kiesinger-Allee 3</p>
                    <p class="no-padding">53175 Bonn</p>
                </div>
                <div class="infopage-content-block">
                    <strong class="no-padding">
                        Dienstsitz Köln:
                    </strong>
                    <div>
                        <p class="no-padding">Waisenhausgasse 36-38a</p>
                        <p class="no-padding">50676 Köln</p>
                    </div>
                </div>
                <div class="infopage-content-block">
                    <div class='flexed-info-row'>
                        <p>Telefon:</p>
                        <p>+49 (0)22899307-0</p>
                    </div>
                    <div class='flexed-info-row'>
                        <p>Telefax:</p>
                        <p>+49 (0)22899307-5207</p>
                    </div>
                    <div class='flexed-info-row'>
                        <p>E-Mail:</p>
                        <a href="mailto:poststelle@bfarm.de" rel='noopener' target='_blank'
                            title="BfArM E-Mail Poststelle">poststelle&#64;bfarm.de</a>
                    </div>
                </div>
                <div>
                    <p>Das BfArM ist eine selbstständige Bundesoberbehörde im Geschäftsbereich des Bundesministeriums
                        für Gesundheit (BMG).</p>
                </div>
            </div>
        </div>
        <div class='infopage-content-block'>
            <h2>Vertreten durch den Präsidenten</h2>
            <p>Prof. Dr. Karl Broich</p>
        </div>
        <div class='infopage-content-block'>
            <h2>Verantwortlich</h2>
            <p class="no-padding">Presse- und Öffentlichkeitsarbeit</p>
            <div class='flexed-info-row'>
                <p>E-Mail:</p>
                <a href="mailto:internet@bfarm.de" rel='noopener' target='_blank' title="BfArM E-Mail Internet">internet&#64;bfarm.de</a>
            </div>
        </div>
        <div class='infopage-content-block'>
            <h2>Umsatzsteueridentifikationsnummer</h2>
            <p>DE 165 893 456</p>
        </div>
        <div class='infopage-content-block'>
            <h2>Realisierung, Technik und Betrieb</h2>
            <p>Bundesdruckerei GmbH, Komandantenstraße 18, 10969 Berlin</p>
        </div>
        <div class='infopage-content-block'>
            <h2>Copyright</h2>
            <p>Das Copyright für Texte und Bilder liegt,
                soweit nicht anders vermerkt, beim BfArM.
                Die im Internetangebot zur Verfügung gestellten Texte,
                Textteile, Grafiken, Tabellen oder Bildmaterialien dürfen ohne vorherige Zustimmung des BfArM nicht
                vervielfältigt,
                verbreitet oder ausgestellt werden.
            </p>
        </div>
        <div class='top-bar-content' *ngIf='!loggedIn'>
            <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left' >
              <span>Zurück</span>
            </ogr-button>
          </div>
    </div>
</div>
