<ng-container *ngIf='(popupConfig$ | async) as popupObject'>
  <ng-container *ngIf='popupObject.show'>
    <div role="dialog"
         aria-modal="true"
         aria-live="assertive"
         cdkTrapFocus
         [cdkTrapFocusAutoCapture]="true"
         class='popup'>
      <div class='popup-body'>
        <div class='popup-header'>
          <h2 id="popup-headline">{{popupObject.header}}</h2>
        </div>
        <div id="popup-content" class='popup-content'>
          <p [innerHtml]='popupObject.content'></p>
        </div>
        <div class='popup-button-container'>
          <ogr-button
            buttonId='close-button'
            color='secondary'
            class='no-bottom-margin btn-margin-right'
            (buttonClick)='closeAction(popupObject)'
          >{{popupObject.closeLabel || "Abbrechen"}}</ogr-button>
          <ogr-button
            buttonId='popup-action-button'
            color='primary'
            class='no-bottom-margin'
            (buttonClick)='popupAction(popupObject)'
            >{{popupObject.buttonLabel}}</ogr-button>
        </div>
      </div>
    </div>
    <div class='popup-background' (click)='closeAction(popupObject)' ></div>
  </ng-container>
</ng-container>
