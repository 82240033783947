import { Component, Input } from '@angular/core';
import { CountDownStore } from '../../../shared';
import { Store } from '@ngrx/store';
import { setNextNavigationIndex } from '../../+store/apkv.actions';

@Component({
  selector: 'app-navigation-next-button',
  templateUrl: './navigation-next-button.component.html',
})
export class NavigationNextButtonComponent {
  @Input() isNextDisabled: boolean;

  constructor(
    private store: Store,
    private countDownStore: CountDownStore,
  ) {}

  handleNext(): void {
    this.countDownStore.resetCounterDuration();
    this.store.dispatch(setNextNavigationIndex());
  }
}
