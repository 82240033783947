<div class='apkv-check-code-container'>
  <h2>Anmeldung Entnahmekrankenhaus</h2>
  <p>
    Bitte geben Sie hier den Code ein, den Sie per Brief erhalten haben sowie die PIN. Verbundkrankenhäuser erhalten für
    jeden Standort einen eigenen Zugangscode.
  </p>
  <p>*Pflichtfeld</p>
  <div class='check-code-form-container'>
    <form [formGroup]='checkCodeForm' (ngSubmit)='handleSubmit()'>
      <fieldset formGroupName="code" id="code">
        <legend id="label-zugangscode"
                [class.error-message]="isFormControlInvalid(codeFormGroup)">Zugangscode*
        </legend>
        <div class='search-box-container'>
          <input type='text' id='codeInput1' name="focus" formControlName='code1' required
                 [attr.aria-describedby]="getErrorCodeHint('code1')" #firstCodeInput aria-label="Zugangscode Teil 1"
                 (paste)="onPaste($event)" [libAutoTab]="'codeInput2'" maxlength="4"
                 class='search-box' [class.error-input]='isFormControlInvalid(codeFormGroup.controls.code1)'/>
          <input type='text' id='codeInput2' name="focus" formControlName='code2' required
                 [attr.aria-describedby]="getErrorCodeHint('code2')" #secondCodeInput
                 aria-label="Zugangscode Teil 2" [libAutoTab]="'codeInput3'" maxlength="4"
                 class='search-box' [class.error-input]='isFormControlInvalid(codeFormGroup.controls.code2)'/>
          <input type='text' id='codeInput3' name="focus" formControlName='code3' required
                 [attr.aria-describedby]="getErrorCodeHint('code3')" #thirdCodeInput
                 aria-label="Zugangscode Teil 3" [libAutoTab]="'codeInput4'" maxlength="4"
                 class='search-box' [class.error-input]='isFormControlInvalid(codeFormGroup.controls.code3)'/>
          <input type='text' id='codeInput4' name="focus" formControlName='code4' required
                 [attr.aria-describedby]="getErrorCodeHint('code4')" #fourthCodeInput
                 aria-label="Zugangscode Teil 4" [libAutoTab]="'pinInput'" maxlength="4"
                 class='search-box' [class.error-input]='isFormControlInvalid(codeFormGroup.controls.code4)'/>
        </div>
        <app-error-message id="error-code-message" class="error-code-message" [ariaDescribedby]="'code'"
                           *ngIf="submitted && checkCodeForm.controls.code.hasError('accessCodeInvalid')"
                           [externClass]="'margin-bottom-20'" [errorText]='errorMessage'></app-error-message>
      </fieldset>
      <label for="pinInput" [class.error-message]='isFormControlInvalid(pinControl)'>PIN*</label>
      <div class='search-box-container'>
        <input type="text" id='pinInput' name="focus" formControlName='pin' required class='search-box'
               aria-describedby="error-pin-message" #pinInput
               [class.error-input]='isFormControlInvalid(pinControl)'/>
      </div>
      <app-error-message id="error-pin-message" class="error-pin-message"
                         *ngIf="submitted && pinControl.hasError('required')"
                         [errorText]='errorMessage'></app-error-message>

      <app-error-message id="error-invalid-message" aria-live="polite"
                         *ngIf="codeOrPinAreInvalid" [errorText]='codeOrPinAreInvalidText'></app-error-message>

      <ogr-button class='check-code-button' icon='right' iconColor='secondary' iconPosition='suffix' type='submit'
                  id='check-code-button' (buttonClick)='handleSubmit()'>Anmelden
      </ogr-button>
      <div class='check-code-spinner-container' *ngIf='(loading$ | async)?.checkCode || (loading$ | async)?.apkvList'>
        <app-spinner [text]='"Zugangscode wird geprüft"'></app-spinner>
      </div>
    </form>
  </div>
</div>
