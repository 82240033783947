<div class="apkv-overview-container">
  <div class="apkv-content-area">
    <div class="apkv-centered-content-container">
      <div>
        <h1>Verwaltung Verantwortlicher Personen (APK-V)</h1>
        <ng-container>
          <app-note>
            <p>
              Es muss <strong>mindestens eine Person aktiv</strong> sein. Sie
              können maximal {{ APKV_LIMIT }} Personen benennen.
            </p>
          </app-note>
        </ng-container>

        <ng-container *ngIf="ekh$ | async as entnahmekrankenhaus">
          <h2 class="headline-3">
            Sie verwalten APK-V für dieses Entnahmekrankenhaus:
          </h2>
          <div class="ogr-card ekh-name-card flexed-ekh-name">
            <div>
              <ogr-icon icon="ekh" width="28" height="26" color="primary">
              </ogr-icon>
            </div>
            <div>
              <div class="ekh-name-element-container">
                <strong class="ekh-name-element-title">Verbundname</strong>
                <p class="ekh-data-wrapped">
                  {{ getValue(entnahmekrankenhaus.verbundname) }}
                </p>
              </div>
              <div class="ekh-name-element-container no-margin">
                <strong class="ekh-name-element-title no-margin">Name</strong>
                <p class="ekh-data-wrapped">
                  {{ getValue(entnahmekrankenhaus.name) }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="apkv-uebersicht-flexed-header">
        <h2>Liste verantwortlicher Personen</h2>
        <div class="overview-button-container">
          <ogr-button
            color="primary"
            class="btn-margin-right"
            buttonId="naming-process-button"
            (buttonClick)="handleCreate()"
            [disabled]="!isCreateActive()"
            >Neu anlegen</ogr-button
          >
        </div>
      </div>
      <div class="ogr-card">
        <div class="apkv-list-tabs-container">
          <div
            *ngIf="apkvList$ | async as apkvList"
            class="apkv-list-header-container"
          >
            <div>
              Alle ({{
                utilsService.getActiveNumber(apkvList?.length || null)
              }})
            </div>
            <hr aria-hidden="true" class="apkv-list-header-underline" />
          </div>
        </div>
        <div>
          <app-table-generator
            [stateList]="APKV_STATE_LIST"
            [tableConfig]="tableConfig"
          ></app-table-generator>
        </div>
      </div>
    </div>
  </div>
</div>
