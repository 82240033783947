<ng-container *ngIf='(namingProcessData$ | async) as namingProcessData'>
  <div class='access-container'>
    <h1>4. Absenden der Daten</h1>
    <ng-container *ngIf='namingProcessData?.workflowType !== "change"'>
      <p>
        Bitte schließen Sie den Vorgang ab,
        indem Sie die Daten an das Bundesinstitut für Arzneimittel und Medizinprodukte (BfArM) versenden.
      </p>
      <p>Bitte beachten Sie: Das Konto einer APK-V kann im Nachhinein nicht mehr gelöscht,
        jedoch von Ihnen deaktiviert oder vom BfArM gesperrt werden.
      </p>
      <app-apkv-access-list
        [apkvDataList]='apkvService?.getApkvDataArrayByObject((namingProcessData$ | async)?.apkvData)'
      ></app-apkv-access-list>
    </ng-container>
    <div class="flex-container">
      <app-navigation-previous-button class='btn-margin-right'></app-navigation-previous-button>
      <ogr-button
        buttonId='submit-apkv-data-button'
        (buttonClick)='handleSubmit()'
      >
        Daten an BfArM übermitteln
      </ogr-button>
    </div>
    <div class='confirmation-code-spinning-container' *ngIf='(loading$ | async)?.createApkv'>
      <app-spinner text='APK-V Daten werden übermittelt.'></app-spinner>
    </div>
  </div>
</ng-container>
