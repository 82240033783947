<div class='deactivate-container'>
  <div class='deactivate-content'>
    <h1>Verantwortliche Person deaktivieren</h1>
    <p [innerHtml]='getDeactivatePersonInnerHtml()'></p>
    <p>Diese Aktion kann nicht rückgängig gemacht werden</p>
    <div class='confirmation-button-container'>
      <ogr-button class='btn-margin-right' (click)='handleBack()' buttonId='previous-button' iconColor='primary'
        icon='left' color='secondary'>
        Zurück
      </ogr-button>
      <ogr-button buttonId='apkv-naming-button' (buttonClick)='handleDeactivate()'>
        Ja, verantwortliche Person deaktivieren
      </ogr-button>
    </div>

  </div>
</div>