import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CountDownStore, UtilsService } from '../../../shared';
import { selectActiveApkv, selectSessionId } from '../../+store/apkv.selectors';
import { ApkvPersonModel } from '../../apkv.model';
import { deactiveApkv } from '../../+store/apkv.actions';

@Component({
  selector: 'app-bestaetigung-deaktivierung',
  templateUrl: './bestaetigung-deaktivierung.component.html',
  styleUrls: ['./bestaetigung-deaktivierung.component.scss'],
})
export class ApkvBestaetigungDeaktivierungComponent implements OnInit {
  apkv: ApkvPersonModel;

  constructor(
    private router: Router,
    private countDownStore: CountDownStore,
    private store: Store,
    private utilsService: UtilsService,
  ) {}

  public ngOnInit(): void {
    this.apkv = this.utilsService.getStoredValue(
      this.store.select(selectActiveApkv),
    );
    if (!this.apkv) {
      this.router.navigate([`/apkv/overview`]);
    }
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    if (!sessionId) {
      this.router.navigate(['/apkv/check-code']);
    }
  }

  getDeactivatePersonInnerHtml(): string {
    return `Sind Sie sicher, dass Sie <strong>${this.utilsService.getFullName(
      this.apkv,
    )}</strong> als verantwortliche Person deaktivieren wollen?`;
  }

  handleDeactivate(): void {
    this.countDownStore.resetCounterDuration();
    this.store.dispatch(deactiveApkv());
    this.router.navigate([`/apkv/overview`]);
  }

  handleBack(): void {
    this.countDownStore.resetCounterDuration();
    this.router.navigate([`/apkv/overview`]);
  }
}
