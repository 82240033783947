import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feedback } from './feedback.model';

@Injectable()
export class FeedbackService {
  private subject = new BehaviorSubject<Feedback>(null);

  feedback$: Observable<Feedback> = this.subject.asObservable();

  public resetFeedbackObject(): void {
    this.subject.next(null);
  }

  public showFeedback(feedback: Feedback): void {
    this.subject.next(feedback);
  }

  public showError(
    message: string,
    httpError: any,
    innerHtmlMessage: string = null,
  ): void {
    if (httpError?.error) {
      if (httpError.error.message) {
        const manuallyMessage = message ? message + ' ' : '';
        message = manuallyMessage + httpError.error.message;
      }
      if (innerHtmlMessage) {
        innerHtmlMessage = httpError.error.message + innerHtmlMessage;
      }
    }
    if (
      httpError &&
      ((httpError.status && httpError.status === 0) ||
        httpError.status === 500 ||
        httpError.status === 503)
    ) {
      message = message + ' Es liegt ein interner technischer Fehler vor.';
    }

    const feedback: Feedback = {
      type: 'error',
      message,
      innerHtmlMessage,
    };
    console.error(feedback.message, httpError);
    this.showFeedback(feedback);
  }
}
