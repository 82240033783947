import { createAction, props } from '@ngrx/store';

enum actionTypes {
  IDENTIFIKATIONSPERSISTENZ_PRUEFUNG = '[Identifikationspersistenz] Session Storage auf erfolgreiche Identifikation Pruefen',
  IDENTIFIKATIONSPERSISTENZ_PRUEFUNG_ERFOLGREICH = '[Identifikationspersistenz] Session Storage Pruefung Erfolgreich',
  IDENTIFIKATIONSPERSISTENZ_PRUEFUNG_FEHLGESCHLAGEN = '[Identifikationspersistenz] Session Fehlgeschlagen',
}

export const identifikationspersistenzPruefung = createAction(
  actionTypes.IDENTIFIKATIONSPERSISTENZ_PRUEFUNG,
);
export const identifikationspersistenzPruefungErfolgreich = createAction(
  actionTypes.IDENTIFIKATIONSPERSISTENZ_PRUEFUNG_ERFOLGREICH,
  props<{ sessionId: string }>(),
);

export const identifikationspersistenzPruefungFehlgeschlagen = createAction(
  actionTypes.IDENTIFIKATIONSPERSISTENZ_PRUEFUNG_FEHLGESCHLAGEN,
);

export const IdentifikationspersistenzActions = {
  identifikationspersistenzPruefung,
  identifikationspersistenzPruefungErfolgreich,
  identifikationspersistenzPruefungFehlgeschlagen,
};
