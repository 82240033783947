import { createReducer, on } from '@ngrx/store';
import * as actions from './apkv.actions';
import { EkhData, NamingProcessData } from 'src/app/overview/apkv.model';
import { ApkvLoadingState } from '../apkv.model';
import { IdentifikationspersistenzActions } from './identifikationspersistenz.actions';

const MAX_NAVIGATION_INDEX = 4;

const APKV_LIST_SORT_ENUM = ['BENANNT', 'FREIGEGEBEN', 'AKTIV', 'INAKTIV'];

const LOADING_INITIAL_STATE: ApkvLoadingState = {
  checkCode: false,
  apkvList: false,
  checkApkvEmails: false,
  downloadzugang: false,
  downloadbestaetigung: false,
  createApkv: false,
};

const EKH_INITIAL_STATE: EkhData = {
  verbundname: '',
  name: '',
};

const NAMINGPROCESSDATA_INITIAL_STATE: NamingProcessData = {
  apkvData: null,
  zugangDownloaded: false,
  bestaetigungDownloaded: false,
  workflowType: '',
  navigationIndex: 0,
  personenFormValidationObject: null,
};

export const initialState = {
  apkvList: [],
  activeApkv: null,
  namingProcessData: NAMINGPROCESSDATA_INITIAL_STATE,
  ekh: EKH_INITIAL_STATE,
  loading: LOADING_INITIAL_STATE,
  error: null,
  sessionId: null,
  codeValidityObject: null,
};

function sortEkhDataApkvs(apkv) {
  return apkv.sort(
    (a, b) =>
      APKV_LIST_SORT_ENUM.indexOf(a.status) -
      APKV_LIST_SORT_ENUM.indexOf(b.status),
  );
}

export const apkvReducer = createReducer(
  initialState,
  on(actions.checkCode, (state) => ({
    ...state,
    loading: { ...state.loading, checkCode: true },
    codeValidityObject: null,
  })),

  on(actions.checkCodeSuccess, (state, { zugangscode, sessionId }) => ({
    ...state,
    zugangscode,
    sessionId,
    loading: { ...state.loading, checkCode: false },
  })),

  on(actions.checkCodeError, (state, { error, codeValidityObject }) => ({
    ...state,
    error,
    loading: { ...state.loading, checkCode: false },
    codeValidityObject,
  })),

  on(actions.downloadPdf, (state, { pdfType }) => ({
    ...state,
    loading: { ...state.loading, [`download${pdfType}`]: true },
  })),

  on(actions.downloadPdfSuccess, (state, { pdfType }) => {
    return {
      ...state,
      loading: { ...state.loading, [`download${pdfType}`]: false },
      namingProcessData: {
        ...state.namingProcessData,
        [`${pdfType}Downloaded`]: true,
      },
    };
  }),

  on(actions.downloadPdfError, (state, { error, pdfType }) => ({
    ...state,
    error,
    loading: { ...state.loading, [`download${pdfType}`]: false },
  })),

  on(actions.retrieveApkvList, (state) => ({
    ...state,
    loading: { ...state.loading, apkvList: true },
  })),

  on(actions.retrieveApkvListSuccess, (state, { ekhData }) => {
    let apkvCopy = [];
    if (ekhData?.apkvs) {
      apkvCopy = [...ekhData.apkvs];
    }
    const sortedList = sortEkhDataApkvs(apkvCopy);
    return {
      ...state,
      apkvList: sortedList,
      ekh: { verbundname: ekhData.verbundname, name: ekhData.name },
      loading: { ...state.loading, apkvList: false },
    };
  }),

  on(actions.retrieveApkvListError, (state, { error }) => ({
    ...state,
    error,
    loading: { ...state.loading, apkvList: false },
  })),

  on(actions.setActiveApkv, (state, { apkv }) => ({
    ...state,
    activeApkv: apkv,
  })),

  on(actions.resetAtiveApkv, (state) => ({
    ...state,
    activeApkv: null,
  })),

  on(actions.deactiveApkv, (state) => ({
    ...state,
    loading: { ...state.loading, apkvList: true },
  })),

  on(actions.deactiveApkvSuccess, (state) => {
    const updatedApkvList = state.apkvList.map((apkv) => {
      if (apkv.apkvId === state.activeApkv.apkvId) {
        return { ...apkv, status: 'INAKTIV' };
      }
      return apkv;
    });
    const sortedApkvList = sortEkhDataApkvs(updatedApkvList);
    return {
      ...state,
      loading: { ...state.loading, apkvList: false },
      apkvList: sortedApkvList,
      activeApkv: null,
    };
  }),

  on(actions.deactiveApkvError, (state, { error }) => ({
    ...state,
    error,
    loading: { ...state.loading, apkvList: false },
    activeApkv: null,
  })),

  on(actions.initNamingProcess, (state) => {
    const namingProcessData = { ...NAMINGPROCESSDATA_INITIAL_STATE };
    return { ...state, namingProcessData: namingProcessData };
  }),

  on(actions.setNextNavigationIndex, (state) => {
    const updatedNavigationIndex =
      state.namingProcessData.navigationIndex < MAX_NAVIGATION_INDEX
        ? state.namingProcessData.navigationIndex + 1
        : state.namingProcessData.navigationIndex;
    return {
      ...state,
      namingProcessData: {
        ...state.namingProcessData,
        navigationIndex: updatedNavigationIndex,
      },
    };
  }),

  on(actions.setPreviousNavigationIndex, (state) => {
    const updatedNavigationIndex =
      state.namingProcessData.navigationIndex === 0
        ? 0
        : state.namingProcessData.navigationIndex - 1;
    return {
      ...state,
      namingProcessData: {
        ...state.namingProcessData,
        navigationIndex: updatedNavigationIndex,
      },
    };
  }),

  on(actions.setNavigationIndex, (state, { index }) => ({
    ...state,
    namingProcessData: { ...state.namingProcessData, navigationIndex: index },
  })),

  on(actions.setNamingProcessApkvData, (state, { apkvData }) => ({
    ...state,
    namingProcessData: { ...state.namingProcessData, apkvData },
  })),

  on(actions.checkApkvEmailsInUserAccount, (state) => ({
    ...state,
    loading: { ...state.loading, checkApkvEmails: true },
    namingProcessData: {
      ...state.namingProcessData,
      personenFormValidationObject: null,
      zugangDownloaded: false,
      bestaetigungDownloaded: false,
    },
  })),

  on(
    actions.checkApkvEmailsInUserAccountSuccess,
    (state, { personenFormValidationObject }) => ({
      ...state,
      loading: { ...state.loading, checkApkvEmails: false },
      namingProcessData: {
        ...state.namingProcessData,
        personenFormValidationObject,
      },
    }),
  ),

  on(actions.checkApkvEmailsInUserAccountError, (state, { error }) => ({
    ...state,
    error,
    namingProcessData: { ...state.namingProcessData },
    loading: { ...state.loading, checkApkvEmails: false },
  })),

  on(actions.createApkv, (state) => ({
    ...state,
    loading: { ...state.loading, createApkv: true },
  })),

  on(actions.createApkvSuccess, (state, { apkvData }) => {
    return {
      ...state,
      namingProcessData: NAMINGPROCESSDATA_INITIAL_STATE,
      loading: { ...state.loading, createApkv: false },
      apkvList: [...apkvData, ...state.apkvList],
    };
  }),

  on(actions.createApkvError, (state, { error }) => ({
    ...state,
    error,
    loading: { ...state.loading, createApkv: false },
  })),

  on(actions.resetCodeValidityObject, (state) => ({
    ...state,
    codeValidityObject: null,
  })),

  on(
    actions.setCodeValidityObject,
    (state, { codeIsInvalid, pinIsInvalid }) => {
      return { ...state, codeValidityObject: { codeIsInvalid, pinIsInvalid } };
    },
  ),

  on(actions.initStore, (state) => {
    return { ...initialState };
  }),

  on(actions.refreshSession, (state) => {
    return { ...state };
  }),

  on(actions.destroySession, (state) => {
    return { ...state };
  }),

  on(
    IdentifikationspersistenzActions.identifikationspersistenzPruefungErfolgreich,
    (state, action) => ({
      ...state,
      sessionId: action.sessionId,
    }),
  ),
);
