<div role="status">
  <ng-container *ngIf='(feedback$ | async ) as feedback'>
    <div *ngIf='feedback' [ngClass]='feedbackContainerClass'>
      <div class='feedback-content'>
        <span class='icon feedback-icon' [ngClass]='feedbackIcon'></span>
        <p *ngIf='!feedback.innerHtmlMessage'>{{feedback?.message}}</p>
        <p *ngIf='feedback.innerHtmlMessage' [innerHtml]='feedback.innerHtmlMessage'></p>
      </div>
      <button class='feedback-button close' aria-hidden='true' aria-label='Snackbar schließen' (click)='onClose()'
              type='button'>
        <ogr-icon icon='close'></ogr-icon>
      </button>
    </div>
  </ng-container>
</div>
