import { Observable } from 'rxjs';
import { PdfModel } from 'src/app.model';
import { PdfTypesEnum } from '../_enums/pdfTypes';

export class UtilsService {
  // Object zum generieren der Entnahmekrankenhaus PDF Downloads
  private ekhPdfObjectTypes = {
    bestaetigung: {
      route: 'erzeuge-bestaetigungsschreiben',
      fileName: 'bestaetigung.pdf',
    },
    zugang: {
      route: 'erzeuge-zugangscodesschreiben',
      fileName: 'zugangscodes.pdf',
    },
    erneuereRegistrierungscode: {
      route: 'erneuere-registrierungscodeschreiben',
      fileName: 'zugangscode.pdf',
    },
  };

  public getPdfObjectByType(type: PdfTypesEnum): PdfModel {
    if (!Object.keys(this.ekhPdfObjectTypes).includes(type)) {
      return null;
    }
    return this.ekhPdfObjectTypes[type];
  }

  public scrollToTop(): void {
    document.getElementById('main-scroll-container').scrollTo(0, 0);
  }

  public getActiveNumber(counter: number): number {
    if (!counter) {
      return 0;
    }
    return counter;
  }

  public getFullName(obj): string {
    if (!obj?.nachname || !obj?.vorname) {
      return '';
    }
    return `${
      obj?.titel ? obj.titel + ' ' : ''
    }${obj?.vorname} ${obj?.nachname}`;
  }

  public getReverseSeperatedFullName(obj): string {
    if (!obj?.nachname || !obj?.vorname) {
      return '';
    }
    return `${obj?.nachname}, ${obj?.vorname}${
      obj?.titel ? ', ' + obj.titel : ''
    }`;
  }

  public getValueByKey(key: string, list: any): string {
    return list[key];
  }

  public getValue(value: string): string {
    return `${value || '-'}`;
  }

  public addLeadingNull(givenNumber: number): string {
    return ('0' + givenNumber).slice(-2);
  }

  public secondsToMinutesTimeConverter(count: number): string {
    const minutes = Math.floor(count / 60);
    const seconds = count % 60;
    return `${this.addLeadingNull(minutes)}:${this.addLeadingNull(seconds)}`;
  }

  public upperCaseFirstChar(givenString: string): string {
    if (givenString && givenString.length > 0) {
      return givenString.charAt(0).toUpperCase() + givenString.slice(1);
    }
    return '';
  }

  public getStoredValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe((v) => (value = v));
    return value;
  }

  public getCurrentDate(): string {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${this.addLeadingNull(date)}.${this.addLeadingNull(month)}.${year}`;
  }
}
