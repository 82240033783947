import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: ` <div class="spinner-content">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <div>{{ text }}</div>
  </div>`,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() text = 'Wird geladen';
}
