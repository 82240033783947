import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ApkvLoadingState,
  ApkvPersonModel,
  ApkvValidationStatus,
  EkhData,
  NamingProcessData,
} from '../apkv.model';

export interface ApkvState {
  apkvList: ApkvPersonModel[];
  ekh: EkhData;
  namingProcessData: NamingProcessData;
  loading: ApkvLoadingState;
  error: any;
  sessionId: string;
  activeApkv: ApkvPersonModel;
  codeValidityObject: ApkvValidationStatus;
}

export interface AppState {
  apkv: ApkvState;
}

export const selectApkvState = createFeatureSelector<ApkvState>('apkv');

export const selectApkvList = createSelector(
  selectApkvState,
  (state: ApkvState) => state?.apkvList,
);

export const selectLoading = createSelector(
  selectApkvState,
  (state: ApkvState) => state?.loading,
);

export const selectSessionId = createSelector(
  selectApkvState,
  (state: ApkvState) => state?.sessionId,
);

export const selectEkhData = createSelector(
  selectApkvState,
  (state: ApkvState) => state?.ekh,
);

export const selectActiveApkv = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.activeApkv,
);

export const selectNamingProcessData = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.namingProcessData,
);

export const selectNavigationIndex = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.namingProcessData?.navigationIndex,
);

export const selectPersonenformObject = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.namingProcessData?.personenFormValidationObject,
);

export const selectError = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.error,
);

export const selectCodeValidityObject = createSelector(
  selectApkvState,
  (apkvs: ApkvState) => apkvs?.codeValidityObject,
);
