<button
    *ngIf="link === undefined"
    [attr.id]="buttonId"
    [disabled]="disabled"
    [ngClass]="{
    'primary-btn': color === 'primary',
    'secondary-btn': color === 'secondary',
    'text-btn': color === 'text',
    'icon-only': iconOnly
    }"
    (click)="click()"
    [type]="type"
    [attr.aria-label]="ariaLabel"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<a
    *ngIf="link !== undefined"
    [attr.id]="buttonId"
    [ngClass]="{
    'primary-btn': color === 'primary',
    'secondary-btn': color === 'secondary',
    'text-btn': color === 'text',
    'icon-only': iconOnly
  }"
    [routerLink]="link"
    [fragment]="fragment"
    [skipLocationChange]="skipLocationChange"
    [attr.aria-label]="ariaLabel"
    (click)="click()"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</a>

<ng-template #btnContent>
  <ogr-icon *ngIf="!!icon && iconPosition === 'prefix'" [icon]="icon" class="icon-prefix" [size]="iconSize" [buttonColor]='color'
            [color]="disabled ? 'disabled' : iconColor"></ogr-icon>
  <ng-content></ng-content>
  <ogr-icon *ngIf="!!icon && iconPosition === 'suffix'" [icon]="icon" class="icon-suffix" [size]="iconSize" [buttonColor]='color'
            [color]="disabled ? 'disabled' : iconColor"></ogr-icon>
</ng-template>
