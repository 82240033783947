import { Component, Input } from '@angular/core';
import { ApkvStateListModel } from '../../../overview/apkv.model';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  @Input() stateList: ApkvStateListModel;
  @Input() state: string;

  isArray(listElement: any): boolean {
    return Array.isArray(listElement);
  }
}
