import { Component } from '@angular/core';

@Component({
  selector: 'app-hilfeseite',
  templateUrl: './hilfeseite.component.html',
  styleUrls: ['./hilfeseite.component.scss'],
})
export class HilfeSeiteComponent {
  public expandedBlocks = {};

  public handleExpandBlock(blockName): void {
    this.expandedBlocks[blockName] = !this.expandedBlocks[blockName];
  }
}
