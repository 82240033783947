<div class='thank-you-container'>
  <div class='thank-you-content'>
    <h1>Vielen Dank</h1>
        <h2 style="margin-top: 50px">Wie geht es jetzt weiter?</h2>

    <ol>
      <li>
        <p class='inside-list'>
          <strong>
            Übermitteln Sie das Dokument "Bestätigung"
          </strong>
          per Post oder per E-Mail an das BfArM.
          Eine ausführliche Anleitung zum sicheren
          Bestätigen finden Sie auf dem Ausdruck.
        </p>
      </li>
        <li>
          <p class='inside-list'>
            <strong>
              Übergeben Sie die Dokumente "Zugangscodes"
            </strong>
            sicher an die von Ihnen benannten verantwortlichen Personen.
          </p>
        </li>
    </ol>
    <p>
      Nachdem Ihre Bestätigung eingetroffen ist,
      erhalten die benannten Personen nach einer Prüfung durch
      das BfArM einen Link zur Registrierung per E-Mail.
      Für den Login benötigen die benannten Personen
      den von Ihnen in Schritt 2 ausgedruckten persönlichen Zugangscode.
    </p>
    <h3>
      Sie möchten weitere Personen als APK-V zu einem späteren Zeitpunkt hinzufügen
      oder die bereits benannten Personen verwalten?
    </h3>
    <p>
      Nutzen Sie dafür den Link und den Zugangscode, den Sie per Brief erhalten haben.
      Bewahren Sie den Brief daher gut auf!
    </p>
    <ogr-button
      (buttonClick)="handleGoToApkvList()"
      class='thank-you-margin-top'
      color='primary'
      icon='right'
      iconColor='secondary'
      iconPosition='suffix'
      buttonId='go-to-overview-button'
    >
    Weiter zur Liste der benannten Personen
    </ogr-button>
  </div>
</div>
