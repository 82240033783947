import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupModel } from './popup.model';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private popupSubject = new BehaviorSubject<PopupModel>({ show: false });
  public popupConfig$: Observable<PopupModel> =
    this.popupSubject.asObservable();

  public get isPopupOpen(): boolean {
    return this.popupSubject.getValue().show;
  }

  public togglePopup(): void {
    const popupValue = this.popupSubject.getValue();
    const show = popupValue.show;
    this.popupSubject.next({ ...popupValue, show: !show });
  }

  public updatePopupConfig(updateObject: PopupModel): void {
    const popupValue = this.popupSubject.getValue();
    this.popupSubject.next({ ...popupValue, ...updateObject });
  }

  public setPopupConfig(config: PopupModel): void {
    this.popupSubject.next({ ...config });
  }

  public getPopupConfig(): Observable<PopupModel> {
    return this.popupConfig$;
  }

  public openCancelPopup(
    callbackFunc?: string,
    callbackService?: string,
  ): void {
    const serviceFunction = callbackFunc ? callbackFunc : 'navigateToLoggedOut';
    const serviceName = callbackService ? callbackService : 'apkvStore';
    const popupConfig: PopupModel = {
      header: 'Vorgang abbrechen?',
      content: `Sie verlassen die Seite zur Benennung der verantwortlichen Personen.
      Alle bisherigen Daten gehen verloren und es wird keine APK-V angelegt.
      Möchten Sie den Vorgang wirklich abbrechen?`,
      buttonLabel: 'Bestätigen',
      closeLabel: 'Bearbeitung fortsetzen',
      serviceName,
      serviceFunction,
      cancelButtonSize: '240px',
      functionButtonSize: '240px',
      show: true,
    };
    this.setPopupConfig(popupConfig);
  }
}
