import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APKV_ROUTES } from './overview/apkv.routes';
import { ImpressumComponent } from './impressum/impressum.component';
import { LoggedOutComponent } from './loggedout/loggedout.component';
import { BarrierefreiheitComponent } from './barrierefreiheit/barrierefreiheit.component';
import { ErrorStatusPageComponent } from './shared/components/error-status-page/error-status-page.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HilfeSeiteComponent } from './hilfeseite/hilfeseite.component';
import { RechtlicheHinweiseComponent } from './rechtliche-hinweise/rechtliche-hinweise.component';

const routes: Routes = [
  { path: '', redirectTo: '/apkv/check-code', pathMatch: 'full' },
  {
    path: 'impressum',
    pathMatch: 'full',
    component: ImpressumComponent,
    title: 'Impressum',
  },
  {
    path: 'barrierefreiheit',
    component: BarrierefreiheitComponent,
    pathMatch: 'full',
    title: 'Barrierefreiheit',
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
    pathMatch: 'full',
    title: 'Datenschutz',
  },
  {
    path: 'rechtliche-hinweise',
    component: RechtlicheHinweiseComponent,
    pathMatch: 'full',
    title: 'Rechtliche Hinweise',
  },
  {
    path: 'hilfe',
    component: HilfeSeiteComponent,
    pathMatch: 'full',
    title: 'Hilfe',
  },
  {
    path: 'logged-out',
    pathMatch: 'full',
    component: LoggedOutComponent,
    title: 'Abmeldung',
  },
  { path: 'apkv', children: APKV_ROUTES },
  {
    path: 'fehler',
    component: ErrorStatusPageComponent,
    title: 'Ein Fehler ist aufgetreten',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Seite nicht gefunden',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
