<ogr-button
  (click)='handleNext()'
  buttonId='wizard-next-button'
  icon='right'
  [iconColor]='isNextDisabled ? "disabled" : "secondary"'
  color='primary'
  iconPosition='suffix'
  [disabled]='isNextDisabled'
>Weiter
</ogr-button>
