<ng-container>
  <ogr-button
    (click)='handlePrevious()'
    buttonId='wizard-previous-button'
    iconColor='primary'
    icon='left'
    color='secondary'
  >Zurück
  </ogr-button>
</ng-container>
