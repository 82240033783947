import { CSP_NONCE, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ImpressumComponent } from './impressum/impressum.component';
import { LoggedOutComponent } from './loggedout/loggedout.component';
import { BarrierefreiheitComponent } from './barrierefreiheit/barrierefreiheit.component';
import { SharedCommonModule } from './shared/shared-common.module';
import { FormatHelperService } from './shared/services/format-helper.service';
import { FeedbackService } from './shared/components/feedback/feedback.service';
import { CountDownStore } from './shared/components/countdown/countdown.store';
import { PopupService } from './shared/components/popup/popup.store';
import { HttpResponseInterceptor } from './shared/interceptors/http-response.interceptor';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ApkvEffects } from './overview/+store/apkv.effects';
import { apkvReducer } from './overview/+store/apkv.reducer';
import { ApkvModule } from './overview/apkv.module';
import { HilfeSeiteComponent } from './hilfeseite/hilfeseite.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { RechtlicheHinweiseComponent } from './rechtliche-hinweise/rechtliche-hinweise.component';
import { TitleStrategy } from '@angular/router';
import { TemplatePageTitleStrategy } from './shared/services/TemplatePageTitleStrategy';
import { IdentifikationspersistenzEffects } from './overview/+store/identifikationspersistenz.effects';

const components = [
  AppComponent,
  HeaderComponent,
  ImpressumComponent,
  HilfeSeiteComponent,
  BarrierefreiheitComponent,
  DatenschutzComponent,
  LoggedOutComponent,
  RechtlicheHinweiseComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedCommonModule,
    HttpClientModule,
    ApkvModule,
    StoreModule.forRoot({
      apkv: apkvReducer,
    }),
    StoreDevtoolsModule.instrument({
      name: 'EKH-Portal - Organspende-Register',
      connectInZone: true,
    }),
    EffectsModule.forRoot([ApkvEffects, IdentifikationspersistenzEffects]),
  ],
  providers: [
    Title,
    FormatHelperService,
    FeedbackService,
    UtilsService,
    CountDownStore,
    PopupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    {
      provide: CSP_NONCE,
      useValue: (window as any).CSP_NONCE,
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
