import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedCommonModule } from 'src/app/shared/shared-common.module';
import { ApkvService } from './apkv.service';
import { ApkvStore } from './apkv.store';
import { RouterModule } from '@angular/router';
import {
  ApkvCheckCodeComponent,
  ApkvNamingProcessComponent,
  ApkvBestaetigungComponent,
  ApkvZugangscodesSchreibenComponent,
  ApkvPersonenComponent,
  ApkvAbsendeDatenComponent,
  ApkvThankYouComponent,
  ApkvUebersichtComponent,
  ApkvBestaetigungDeaktivierungComponent,
  ApkvAccessListComponent,
  StartComponent,
  WelcomeComponent,
  NavigationPreviousButtonComponent,
  NavigationNextButtonComponent,
} from './components';
import { TemplatePageTitleStrategy } from '../shared/services/TemplatePageTitleStrategy';

@NgModule({
  declarations: [
    ApkvCheckCodeComponent,
    ApkvNamingProcessComponent,
    ApkvBestaetigungComponent,
    ApkvZugangscodesSchreibenComponent,
    ApkvPersonenComponent,
    ApkvAbsendeDatenComponent,
    ApkvThankYouComponent,
    ApkvUebersichtComponent,
    ApkvBestaetigungDeaktivierungComponent,
    ApkvAccessListComponent,
    StartComponent,
    WelcomeComponent,
    NavigationNextButtonComponent,
    NavigationPreviousButtonComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedCommonModule,
    FormsModule,
    RouterModule,
  ],
  providers: [ApkvStore, ApkvService, TemplatePageTitleStrategy],
})
export class ApkvModule {}
