<div class="apkv-status-error main-container margin-top-80">
  <div class="apkv-status-error image-row">
    <!--HTMl kurz und sauber halten, durch ein leeres alt wird das bild nicht vorgelesen und es wird kein weiteres aria-hidden gebraucht-->
    <img class="apkv-status-error image" src="assets/images/logos/Laptop_Isometric_Error.svg" alt="">
  </div>
  <div class="margin-top-30">
    <h1 class="apkv-status-error heading">Ein Fehler ist aufgetreten</h1>
  </div>
  <div class="apkv-status-error paragraph-row margin-top-30">
    <p class="apkv-status-error paragraph">Bitte versuchen Sie es erneut.</p>
  </div>
  <div class="apkv-status-error button-row margin-top-30">
    <app-link-button [label]="buttonName" routerLink=""></app-link-button>
  </div>
</div>
