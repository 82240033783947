<div class='infopage-container'>
  <div class='infopage-content content-layout'>
    <div class='top-bar-content btn-margin-button' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left'>
        <span>Zurück</span>
      </ogr-button>
    </div>
    <h1>Rechtliche Hinweise</h1>
    <h2>Open-Source-Bibliotheken</h2>
    <p>Im EKH-Portal werden folgende Open-Source-Bibliotheken verwendet:</p>
    <div class='infopage-content-block flexed-block' lang='en'>
      <ul>
        <li *ngFor="let dependency of dependencies">
          <div class="infopage-content-block-license-summary">
            <p>{{dependency.name}}</p>
            <p>{{dependency.licenseType}}</p>
          </div>
        </li>
      </ul>
      <h2 lang='de'>Lizenzen</h2>
      <ul>
        <li *ngFor="let dependency of dependencies">
          <h3>{{dependency.name}}</h3>
          <pre>{{dependency.licenseText}}</pre>
        </li>
      </ul>
    </div>
    <div class='top-bar-content' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left'>
        <span>Zurück</span>
      </ogr-button>
    </div>
  </div>
</div>