import { Component, Input, OnChanges } from '@angular/core';

export type NoteKind = 'hint' | 'danger';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnChanges {
  @Input() kind: NoteKind = 'hint';
  showNote = true;
  noteHeadline = '';

  constructor() {
    this.noteHeadline = this.setHeadline(this.kind);
  }

  ngOnChanges() {
    this.noteHeadline = this.setHeadline(this.kind);
  }

  setHeadline(kind: NoteKind) {
    return kind === 'danger' ? 'Achtung!' : 'Hinweis';
  }

  getContainerClass(): string {
    return `note-container note-kind-${this.kind}`;
  }

  handleClose(): void {
    this.showNote = false;
  }
}
