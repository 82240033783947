import { Component, Input, OnInit } from '@angular/core';
import { TableConfigItem } from './table-generator.model';
import { UtilsService } from '../../services/utils.service';
import { Store } from '@ngrx/store';
import {
  selectApkvList,
  selectLoading,
} from 'src/app/overview/+store/apkv.selectors';
import {
  ApkvPersonModel,
  ApkvStateListModel,
} from 'src/app/overview/apkv.model';
import { Router } from '@angular/router';
import { setActiveApkv } from '../../../overview/+store/apkv.actions';
import { PopupService } from '../popup/popup.store';
import { PdfTypesEnum } from '../../_enums/pdfTypes';
import { APKV_STATES } from '../../_enums/apkv_states';
import { PopupModel } from '../popup/popup.model';

@Component({
  selector: 'app-table-generator',
  templateUrl: './table-generator.component.html',
  styleUrls: ['./table-generator.component.scss'],
})

// >>>>>>>>>>>>*<<<<<<<<<<<<<<
// Verfügbare Componenten für Table-Config
// >>>>>>>>>>>>*<<<<<<<<<<<<<<
// string => Text in p element
// bold => Text in strong element
// link => verlinkung auf andere Seite
// status => Anzeige des aktuellen Statuus des Elements
// >>>>>>>>>>>>*<<<<<<<<<<<<<<
export class TableComponent implements OnInit {
  @Input() tableConfig: [TableConfigItem];
  @Input() stateList: ApkvStateListModel;
  private activeApkvId: string;
  public entity = 'apkv';
  public tabledescription: string;
  public scrollButtonIsShown = false;
  public apkvList$ = this.store.select(selectApkvList);
  public loading$ = this.store.select(selectLoading);
  public apkvStates = APKV_STATES;
  public apkvStatesArray = Object.keys(this.apkvStates);

  constructor(
    public utilsService: UtilsService,
    private store: Store,
    private router: Router,
    private popupService: PopupService,
  ) {}

  public ngOnInit(): void {
    this.tabledescription = `${this.entity}-table`;
  }

  public getCellId(key: string, index: number): string {
    return `${key}-${index}`;
  }

  public getLabelByValue(value: string): string {
    if (!value) {
      return '–';
    }
    return value;
  }

  public getAriaLiveDescription(list: ApkvPersonModel[]): string {
    if (!list || list?.length < 0) {
      return '';
    }
    return `Es werden ${list.length}
      Ergebnisse für die aktuelle Suche angezeigt`;
  }

  public shouldRenderTableCell(
    row: TableConfigItem,
    cellType: string,
    status: string,
  ): boolean {
    if (status === 'INAKTIV' && row.type !== 'state') {
      return 'disabled' === cellType;
    }
    return row.type === cellType;
  }

  public handleDisable(apkv: ApkvPersonModel): void {
    this.store.dispatch(setActiveApkv({ apkv }));
    this.router.navigate([`/apkv/overview/deactivate`]);
  }

  public handleOpenNewCodeDialog(apkv): void {
    this.activeApkvId = apkv.apkvId;
    const popupConfig: PopupModel = {
      header: 'Neuer Registrierungscode',
      content: `Wenn Sie einen neuen Registrierungscode für
      ${this.utilsService.getFullName(
        apkv,
      )} erstellen, verliert der bisherige Registrierungscode
      seine Gültigkeit und kann nicht mehr verwendet werden.`,
      buttonLabel: 'Neuen Code erstellen (PDF)',
      closeLabel: 'Abbrechen',
      actionName: 'downloadPdf',
      actionProps: { apkv, pdfType: PdfTypesEnum.erneuereRegistrierungscode },
      cancelButtonSize: '240px',
      functionButtonSize: '240px',
      show: true,
    };
    this.popupService.setPopupConfig(popupConfig);
  }

  public isActiveApkv(apkv: ApkvPersonModel): boolean {
    return apkv.apkvId === this.activeApkvId;
  }
}
