import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PopupService } from 'src/app/shared/components/popup/popup.store';
import { ApkvStore } from '../overview/apkv.store';
import { PopupModel } from '../shared/components/popup/popup.model';
import { ApkvService } from '../overview/apkv.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public loggedIn: boolean;
  private logoutText: string;
  private logoutDialogHeader: string;
  private subscription = new Subscription();
  public showBackLink = false;
  private overviewLogoutText =
    'Sie werden abgemeldet und Ihre Sitzung wird beendet. Möchten Sie die Seite wirklich verlassen?';
  private overviewLogoutDialogHeader = 'Seite verlassen?';

  constructor(
    private router: Router,
    private popupService: PopupService,
    private apkvService: ApkvService,
    private apkvStore: ApkvStore,
  ) {}

  public ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        const currentUrlIsInfoPage =
          this.apkvService.backLinksAvailableOn.reduce(
            (urlIsInfopage, currentUrl) => {
              if (val.url?.includes(currentUrl)) {
                urlIsInfopage = true;
              }
              return urlIsInfopage;
            },
            false,
          );
        const currentUrlIsInfoPageFromOverview =
          currentUrlIsInfoPage && this.apkvService?.url?.includes('overview');
        const currentUrlIncludesOverview = val.url?.includes('overview');
        const currentUrlIsInfoPageNotLoggedIn =
          currentUrlIsInfoPage &&
          this.apkvService.url &&
          !this.apkvService.url.includes('check-code') &&
          !this.apkvService.url.includes('logged-out');

        this.loggedIn =
          val.url?.includes('naming') ||
          currentUrlIncludesOverview ||
          val.url?.includes('thank-you') ||
          currentUrlIsInfoPageNotLoggedIn;

        this.logoutText =
          currentUrlIncludesOverview || currentUrlIsInfoPageFromOverview
            ? this.overviewLogoutText
            : '';
        this.logoutDialogHeader =
          currentUrlIncludesOverview || currentUrlIsInfoPageFromOverview
            ? this.overviewLogoutDialogHeader
            : '';
      }
    });

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          tap((event: NavigationEnd) => {
            this.showBackLink =
              this.apkvService.backLinksAvailableOn.includes(
                event.urlAfterRedirects,
              ) && this.apkvService.url?.length > 0;
          }),
        )
        .subscribe(),
    );
  }

  public finishFunction(): void {
    this.apkvStore.navigateToLoggedOut();
  }

  public onOpenFinishPopup(): void {
    if (!!this.logoutText && !!this.logoutDialogHeader) {
      const popupConfig: PopupModel = {
        header: this.logoutDialogHeader,
        content: this.logoutText,
        buttonLabel: 'Bestätigen',
        closeLabel: 'Bearbeitung fortsetzen',
        serviceName: 'apkvStore',
        serviceFunction: 'navigateToLoggedOut',
        cancelButtonSize: '240px',
        functionButtonSize: '240px',
        show: true,
      };
      this.popupService.setPopupConfig(popupConfig);
    } else {
      this.popupService.openCancelPopup();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public goBackToForm(): void {
    this.router.navigate([this.apkvService?.url]);
  }

  public getFormStateURL(): string {
    return this.apkvService?.url;
  }
}
