import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IconType =
  | ''
  | 'left'
  | 'right'
  | 'add'
  | 'ekh'
  | 'clock'
  | 'logout'
  | 'chevron-down'
  | 'chevron-up'
  | 'linkextern'
  | 'close'
  | 'more';

export type IconColor =
  | 'coal'
  | 'primary'
  | 'info'
  | 'secondary'
  | 'disabled'
  | 'orange';

@Component({
  selector: 'ogr-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon: IconType = '';
  @Input() size?: number;
  @Input() width?: number;
  @Input() height?: number;
  @Input() color?: IconColor = 'coal';
  @Input() buttonColor = 'primary';

  getIconSize(width?: number, size?: number): string {
    return `${(width ? width : size) ?? 16}px`;
  }

  getIconColorString(): string {
    if (this.color === 'disabled') {
      return `is-disabled-${this.buttonColor}`;
    }
    return this.color ? 'is-' + this.color : undefined;
  }
}
