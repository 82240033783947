import { Component, OnInit, HostListener } from '@angular/core';
import { ApkvPersonenComponent } from '../verantwortliche-personen/apkv-personen.component';
import { ApkvBestaetigungComponent } from '../bestaetigungs-codes/apkv-bestaetigung.component';
import { ApkvZugangscodesSchreibenComponent } from '../zugangscodes-schreiben/apkv-zugangscodes-schreiben.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApkvAbsendeDatenComponent } from '../absende-daten/apkv-absende-daten.component';
import {
  selectEkhData,
  selectNavigationIndex,
  selectSessionId,
} from '../../+store/apkv.selectors';
import { Store } from '@ngrx/store';
import { setNavigationIndex } from '../../+store/apkv.actions';
import { TemplatePageTitleStrategy } from '../../../shared/services/TemplatePageTitleStrategy';
import { UtilsService } from '../../../shared';
import {
  WizardComponent,
  WizardConfig,
} from '../../../shared/components/wizard-navigation/wizard-navigation.model';

@Component({
  selector: 'app-naming-process',
  templateUrl: './apkv-naming-process.component.html',
  styleUrls: ['./apkv-naming-process.component.scss'],
})
export class ApkvNamingProcessComponent implements OnInit {
  wizardConfig: WizardConfig[] = [
    {
      label: '1. Verantwortliche Personen',
      component: ApkvPersonenComponent,
      title: 'Verantwortliche Personen Benennen',
      routeFragment: 'schritt-1',
    },
    {
      label: '2. Sichere Bestätigung',
      component: ApkvBestaetigungComponent,
      title: 'Sichere Bestätigung',
      routeFragment: 'schritt-2',
    },
    {
      label: '3. Zugangscodes',
      component: ApkvZugangscodesSchreibenComponent,
      title: 'Zugangscodes',
      routeFragment: 'schritt-3',
    },
    {
      label: '4. Absenden',
      component: ApkvAbsendeDatenComponent,
      title: 'Absenden',
      routeFragment: 'schritt-4',
    },
  ];

  ekh$ = this.store.select(selectEkhData);
  navigationIndex$ = this.store.select(selectNavigationIndex);

  // Bei Reload oder Seite schließen im APK-V Wizard wird eine
  // Browser Mitteilung getriggert, die vor Verlorengehen der Daten warnt
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event) {
    $event.preventDefault();
    $event.returnValue = true;
  }

  constructor(
    private router: Router,
    private store: Store,
    private utilsService: UtilsService,
    private titleStrategy: TemplatePageTitleStrategy,
    private route: ActivatedRoute,
  ) {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.store.dispatch(
          setNavigationIndex({
            index: this.getWizardConfigIndexByRouteFragment(fragment),
          }),
        );
        router.navigate([]);
      }
    });
  }

  ngOnInit(): void {
    const sessionId = this.utilsService.getStoredValue(
      this.store.select(selectSessionId),
    );
    const storedNavigationIndex = this.utilsService.getStoredValue(
      this.store.select(selectNavigationIndex),
    );
    if (!sessionId) {
      this.router.navigate(['/apkv/check-code']);
    }
    this.store.dispatch(
      setNavigationIndex({ index: storedNavigationIndex || 0 }),
    );
  }

  getWizardConfigIndexByRouteFragment(fragment: string): number {
    return this.wizardConfig.findIndex(
      (element) => element.routeFragment === fragment,
    );
  }

  getComponentByNavIndex(
    config: WizardConfig[],
    navIndex: number,
  ): WizardComponent {
    if (config[navIndex]?.title) {
      this.titleStrategy.updateTitle(config[navIndex].title);
    }
    return config[navIndex]?.component;
  }
}
