import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  static readonly mainTitle = 'EKH-Portal - Organspende-Register';
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot | string): void {
    const subTitle =
      typeof routerState === 'string'
        ? routerState
        : this.buildTitle(routerState);
    if (subTitle !== undefined) {
      const title =
        (subTitle ? subTitle + ' - ' : '') +
        TemplatePageTitleStrategy.mainTitle;
      this.title.setTitle(title);
    }
  }
}
