<div class='apk-naming-process-container'>
  <div class='apk-naming-process-content'>
    <nav>
      <app-wizard-navigation [currentNavIndex]='( navigationIndex$ | async)' [wizardItems]="wizardConfig">
      </app-wizard-navigation>
      <div class='line-break-sm'></div>
      <div class='ekh-name-wizard'>
        <ng-container *ngIf='(ekh$ | async) as entnahmekrankenhaus'>
          <ogr-icon icon='ekh' width='19' height='18' color='primary'></ogr-icon>
          <div class='wizard-ekh-name-container'>
            <strong>Verbundname:</strong>
            <p class='ekh-data-wrapped'>{{entnahmekrankenhaus.verbundname || "-"}}</p>
          </div>
          <div class='wizard-ekh-name-container'>
            <strong>
              Name:
            </strong>
            <p class='ekh-data-wrapped'>{{entnahmekrankenhaus.name}}
            </p>
          </div>
        </ng-container>
      </div>
    </nav>
    <div>
      <ng-container *ngComponentOutlet='getComponentByNavIndex(wizardConfig, (navigationIndex$ | async))'>
      </ng-container>
    </div>
  </div>
</div>
