<div class='infopage-container'>
  <div class='infopage-content content-layout'>
    <div class='infopage-content-block'>
      <h1>Wie können wir Ihnen helfen?</h1>
      <p>
        Sie haben Fragen? Hier finden Sie die Antworten auf häufig gestellte Fragen rund um das Thema EKH-Portal.
      </p>
    </div>
    <h2>Fragen zum EKH-Portal</h2>
    <div class='infopage-content-block'>
      <button [attr.aria-expanded]="expandedBlocks['block1'] ?? false" aria-controls="expandedBlock1"
              class='hilfeseite-header' (click)="handleExpandBlock('block1')">
        <h3>
          Was ist das Organspende-Register und das EKH-Portal?
        </h3>
        <ogr-icon height='10' [icon]="expandedBlocks['block1'] ? 'chevron_up' : 'chevron_down'" color="orange">
        </ogr-icon>
      </button>
      <ng-container *ngIf="expandedBlocks['block1']">
        <p id="expandedBlock1" aria-hidden="true">
          Das Organspende-Register ist ein zentrales elektronisches Verzeichnis,
          in dem die Entscheidung für oder gegen eine Organ- und Gewebespende festgehalten werden kann.
          Das Organspende-Register wird durch das Bundesinstitut für Arzneimittel und Medizinprodukte
          (BfArM) geführt. Das Organspende-Register ist nach Nutzergruppen und Funktionalitäten in
          mehrere Portale unterteilt. Eines dieser Portale ist das EKH-Portal. Im EKH-Portal können
          Entnahmekrankenhäuser APK-verantwortliche Personen (APK-V) gegenüber dem Bundesinstitut für
          Arzneimittel und Medizinprodukte (BfArM) benennen und verwalten (ergänzen, deaktivieren oder
          wechseln). Die benannten APK-V benennen und verwalten im Abrufportal die abrufberechtigten
          Personen (im) Krankenhaus (APK), die auf das Organspende-Register zugreifen dürfen.
        </p>
      </ng-container>
    </div>
    <div class='infopage-content-block'>
      <button [attr.aria-expanded]="expandedBlocks['block2'] ?? false" aria-controls="expandedBlock2"
              class='hilfeseite-header' (click)="handleExpandBlock('block2')">
        <h3>
          Wer ist berechtigt auf das EKH-Portal zuzugreifen?
        </h3>
        <ogr-icon height='10' [icon]="expandedBlocks['block2'] ? 'chevron_up' : 'chevron_down'" color="orange">
        </ogr-icon>
      </button>
      <ng-container *ngIf="expandedBlocks['block2']">
        <p aria-hidden="true" id="expandedBlock2">
          Die Geschäftsführung eines Entnahmekrankenhauses
          oder eine von ihr beauftragte Person sind berechtigt, auf das EKH-Portal zuzugreifen.
          Die Identifikation erfolgt über den an sie postalisch versandten Zugangscode.
          Dieser ist für 30-Tage gültig.
          Für einen neuen Zugangscode kontaktieren Sie bitte das BfArM unter
          <a href='mailto:ekh@organspende-register.de' rel='noopener' target='_blank'>
            ekh&#64;organspende-register.de
          </a>.
        </p>
      </ng-container>
    </div>
    <div class='infopage-content-block'>
      <button [attr.aria-expanded]="expandedBlocks['block3'] ?? false" aria-controls="expandedBlock3"
              class='hilfeseite-header' (click)="handleExpandBlock('block3')">
        <h3>
          Was bedeuten die Abkürzungen APK, APK-V, EKH und BfArM und was sind ihre Rollen?
        </h3>
        <ogr-icon height='10' [icon]="expandedBlocks['block3'] ? 'chevron_up' : 'chevron_down'" color="orange">
        </ogr-icon>
      </button>
      <ng-container *ngIf="expandedBlocks['block3']">
        <div id="expandedBlock3" aria-hidden="true">
          <p>
            <strong>APK</strong>
            ist eine
            <strong>a</strong>brufberechtigte
            <strong>P</strong>erson (im)
            <strong>K</strong>rankenhaus.
            Personen mit dieser Berechtigung können Erklärungen zur Organ- und Gewebespende aus
            dem Organspender-Register über das Abrufportal abrufen.
          </p>
          <p>
            Die <strong>APK-V</strong>
            (<strong>APK-v</strong>erantwortliche Person) administriert die abrufberechtigten
            Personen eines Entnahmekrankenhauses über das EKH-Portal, d.h.
            sie kann diese im Organspende-Register benennen oder deaktivieren.
            APK-V sind Mitarbeiter eines Entnahmekrankenhauses.
            Eine APK-V erhält immer die APK Rolle zugeteilt und ist somit auch abrufberechtigt.
          </p>
          <p>
            <strong>EKH</strong> (
            <strong>E</strong>ntnahme
            <strong>k</strong>ranken
            <strong>h</strong>äuser) nach § 9a TPG sind Krankenhäuser,
            die nach ihrer räumlichen und personellen Ausstattung in der Lage sind,
            Organentnahmen zu ermöglichen. Sie unterliegen der staatlichen Aufsicht der Länder.
            Die zuständige Landesbehörde benennt die Entnahmekrankenhäuser gegenüber der Koordinierungsstelle
            „Deutsche Stiftung Organtransplantation“ (DSO).
            In Deutschland gibt es rund 1.250 Entnahmekrankenhäuser.
            Laut Transplantationsgesetz muss das EKH dem Bundesinstitut
            für Arzneimittel und Medizinprodukte (BfArM) abrufberechtigte Personen benennen.
          </p>
          <p>
            <strong>BfArM</strong> ist das
            <strong>B</strong>undesinstitut
            <strong>f</strong>ür
            <strong>Ar</strong>zneimittel und
            <strong>M</strong>edizinprodukte und verantwortlich für das Organspende-Register.
          </p>
        </div>
      </ng-container>
    </div>
    <div class='infopage-content-block'>
      <button [attr.aria-expanded]="expandedBlocks['block4'] ?? false" aria-controls="expandedBlock4"
              class='hilfeseite-header' (click)="handleExpandBlock('block4')">
        <h3>
          Was ist die Aufgabe einer APK-V?
        </h3>
        <ogr-icon height='10' [icon]="expandedBlocks['block4'] ? 'chevron_up' : 'chevron_down'" color="orange">
        </ogr-icon>
      </button>
      <ng-container *ngIf="expandedBlocks['block4']">
        <p id="expandedBlock4" aria-hidden="true">
          APK-verantwortliche Personen (APK-V) benennen und verwalten im Abrufportal
          abrufberechtigte Person (APK) in Ihrem Entnahmekrankenhaus für das Organspende-Register.
        </p>
      </ng-container>
    </div>
    <div class='infopage-content-block'>
      <h2>
        Fragen zur Benennung einer APK-V
      </h2>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block5'] ?? false" aria-controls="expandedBlock5"
                class='hilfeseite-header' (click)="handleExpandBlock('block5')">
          <h3>Wen darf ich als APK-V benennen?</h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block5'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block5']">
          <div id="expandedBlock5" aria-hidden="true">
            <p>
              Sie benötigen Vorname(n), Nachname, berufliche E-Mail-Adresse und Telefonnummer der APK-V.
              Bitte tragen Sie dieselben Namen ein, wie sie auch auf dem eHBA der designierten APK-V angegeben
              sind.
            </p>
            <p>
              Die APK-V muss einen elektronischen Heilberufsausweises (eHBA) zur Registrierung und
              Anmeldung im Abrufportal des Organspende-Registers besitzen,
              sowie Zugriff auf einen Arbeitsplatz mit eHBA-Kartenlesegerät und Anbindung an die
              Telematik-Infrastruktur innerhalb des Krankenhauses haben.
            </p>
          </div>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block6'] ?? false" aria-controls="expandedBlock6"
                class='hilfeseite-header' (click)="handleExpandBlock('block6')">
          <h3>Was sind die notwendigen Schritte,
            damit eine abrufberechtigte Person eine Erklärung in meinem Entnahmekrankenhaus abrufen kann?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block6'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block6']">
          <div id="expandedBlock6" aria-hidden="true">
            <p>
              Zunächst benötigen Sie Ihren Zugangscode für das EKH-Portal.
              Dieser ist der Geschäftsführung jedes Entnahmekrankenhauses (EKH) postalisch durch das
              BfArM zugesandt worden. Mit dem Zugangscode haben Sie Zugriff auf das EKH-Portal,
              in dem Sie bis zu drei APK-V benennen können.
              Durch den Vorgang der APK-V Benennung leitet Sie das EKH-Portal.
            </p>
            <p>
              Während des APK-V Benennungsprozesses im EKH-Portal werden zwei Dokumente erstellt:
            </p>
            <ul>
              <li>
                Das Portal erstellt ein Dokument zur schriftlichen Benennung der
                APK-V gegenüber dem BfArM. Dieses sollte schnellstmöglich unterschrieben und
                gestempelt über einen der aufgeführten Wege an das BfArM übermittelt werden.
                Hat das BfArM dieses Schreiben erhalten und geprüft,
                kann es die benannten APK-V freigeben.
              </li>
              <li>
                Das Portal erstellt ein Dokument mit einem persönlichen Zugangscode für jede benannte APK-V.
                Bitte übergeben Sie den Zugangscode sicher an die benannte APK-V.
              </li>
            </ul>
            <p>
              Sie schließen den Prozess der APK-V Benennung im EKH-Portal mit der Übermittlung der
              Daten an das BfArM ab (Daten an BfArM übermitteln).
              Danach müssen Sie die oben genannten Dokumente an die entsprechenden Stellen übermitteln.
            </p>
            <p>
              Die benannten APK-V erhalten nach erfolgter Freigabe durch das BfArM eine
              E-Mail mit einem personalisierten Registrierungslink. Dieser Link ist 30 Tage lang gültig.
              Mit personalisiertem Registrierungslink und dem ausgehändigten persönlichen Zugangscode
              können sich APK-V nun im Abrufportal des Organspende-Registers registrieren.
              Für den Registrierungs- und Anmeldeprozess wird ein eHBA benötigt.
            </p>
            <p>
              Nach erfolgreicher Registrierung können APK-V bereits Erklärungen abrufen.
              Zudem können APK-V weitere abrufberechtigte Personen im Krankenhaus (APK) im Abrufportal benennen.
              Das Abrufportal leitet den APK-V durch den Benennungsprozess.
              Auch die benannten APKs brauchen für ihren Zugang zum Organspende-Register einen eHBA.
            </p>
            <p>
              Nach erfolgreicher Registrierung der APK können diese Erklärungen zur Organ- und Gewebespende aus dem
              Register
              abrufen.
            </p>
          </div>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block7'] ?? false" aria-controls="expandedBlock7"
                class='hilfeseite-header' (click)="handleExpandBlock('block7')">
          <h3>
            Wie viele APK-V kann ich für ein Entnahmekrankenhaus (EKH) benennen?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block7'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block7']">
          <p id="expandedBlock7" aria-hidden="true">
            Es können bis zu drei APK-V für ein EKH benannt werden. Es ist mindestens eine APK-V zu benennen.
            Nach Freigabe durch das BfArM und erfolgreicher Registrierung der APK-V ist ihr Status aktiv.
            Ohne aktive APK-V kann ihr EKH keine Erklärungen suchen oder einsehen.
          </p>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block8'] ?? false" aria-controls="expandedBlock8"
                class='hilfeseite-header' (click)="handleExpandBlock('block8')">
          <h3>

            Die von mir benannte APK-V ist bereits im Register als Nutzer hinterlegt, was bedeutet das für mich?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block8'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block8']">
          <div id="expandedBlock8" aria-hidden="true">
            <p>
              Bitte prüfen sie zuerst, ob die Person für Ihr EKH nicht schon als APK-V oder APK angelegt wurde.
              Wenn die Person bereits für Ihr EKH als APK benannt wurde, kann sie,
              bei Verwendung der gleichen E-Mail-Adresse, nicht die Rolle APK-V erhalten.
              Diese Funktion wird in einer späteren Ausbaustufe umgesetzt.
            </p>
            <p>
              Wenn eine Person bereits für ein anderes EKH als APK-V oder APK benannt wurde, kann sie,
              bei Verwendung der gleichen E-Mail-Adresse, nicht erneut benannt werden.
              Diese Funktion wird zeitnah in der nächsten Ausbaustufe umgesetzt.
            </p>
          </div>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block9'] ?? false" aria-controls="expandedBlock9"
                class='hilfeseite-header' (click)="handleExpandBlock('block9')">
          <h3>
            Ich habe die falsche Person als APK-V angelegt. Kann ich das rückgängig machen?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block9'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block9']">
          <p id="expandedBlock9" aria-hidden="true">
            Aktive oder vom BfArM freigegebene APK-V können Sie in der APK-V Liste deaktivieren.
            Sollte die APK-V noch nicht freigegeben worden sein oder ihr Zugangscode ist abgelaufen,
            wenden Sie sich bitte an ekh&#64;organspende-register.de.
            Sollte die Deaktivierung zeitkritisch sein, kann das BfArM den User auch sperren.
          </p>
        </ng-container>
      </div>
    </div>
    <div class='infopage-content-block'>
      <h2>
        Fragen zu Dokumenten und E-Mails
      </h2>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block10'] ?? false" aria-controls="expandedBlock10"
                class='hilfeseite-header' (click)="handleExpandBlock('block10')">
          <h3>
            Mein Zugangscode für das EKH-Portal ist abgelaufen oder verloren gegangen. Was soll ich tun?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block10'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block10']">
          <p id="expandedBlock10" aria-hidden="true">
            Bitte wenden Sie sich zur Beantragung eines neuen Zugangscodes an
            <a href='mailto:ekh@organspende-register.de' rel='noopener' target='_blank'>
              ekh&#64;organspende-register.de
            </a>
          </p>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block11'] ?? false" aria-controls="expandedBlock11"
                class='hilfeseite-header' (click)="handleExpandBlock('block11')">
          <h3>
            Ich habe das Bestätigungsschreiben an das BfArM verloren, was soll ich tun?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block11'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block11']">
          <div id="expandedBlock11" aria-hidden="true">
            <p>
              Sollten Sie das Bestätigungsschreiben an das BfArM verloren haben,
              durchsuchen Sie bitte zunächst die Downloads ihres Browsers und die Speicherorte Ihres
              Endgerätes.
              Der Dateiname des Bestätigungsschreibens lautet bestaetigung.pdf.
            </p>
            <p>
              Sollten Sie das Bestätigungsschreiben auch dort nicht finden,
              wenden Sie sich bitte an
              <a href='mailto:ekh@organspende-register.de' rel='noopener' target='_blank'>
                ekh&#64;organspende-register.de
              </a>
            </p>
          </div>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block12'] ?? false" aria-controls="expandedBlock12"
                class='hilfeseite-header' (click)="handleExpandBlock('block12')">
          <h3>
            Der Registrierungscode für die APK-V ist verloren gegangen, was soll ich tun?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block12'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block12']">
          <p id="expandedBlock12" aria-hidden="true">
            Solange sich eine APK-V noch nicht registriert hat, befindet sich in der
            APK-V-Liste neben dem Status ein Button, mit dem Sie einen neuen Registrierungscode
            erstellen können.
            Sobald der neue Code erstellt ist,
            verliert der alte Code seine Gültigkeit und kann nicht mehr verwendet werden.
          </p>
        </ng-container>
      </div>
      <div class='infopage-content-block'>
        <button [attr.aria-expanded]="expandedBlocks['block13'] ?? false" aria-controls="expandedBlock13"
                class='hilfeseite-header' (click)="handleExpandBlock('block13')">
          <h3>
            Die E-Mail mit dem Registrierungslink für die APK-V ist abgelaufen oder verloren gegangen,
            was soll ich tun?
          </h3>
          <ogr-icon height='10' [icon]="expandedBlocks['block13'] ? 'chevron_up' : 'chevron_down'" color="orange">
          </ogr-icon>
        </button>
        <ng-container *ngIf="expandedBlocks['block13']">
          <div id="expandedBlock13" aria-hidden="true">
            <p>
              Bitte wenden Sie sich zur Übermittlung einer neuen E-Mail mit Registrierungslink an
              <a href='mailto:ekh@organspende-register.de' rel='noopener' target='_blank'>
                ekh&#64;organspende-register.de
              </a>.
              Sobald der neue Link versendet wurde,
              verliert der alte Link seine Gültigkeit und kann nicht mehr verwendet werden. Bitte
              beachten Sie,
              dass die Bearbeitung der Anfrage ca 2 Werktage dauern kann.
            </p>
          </div>
        </ng-container>
      </div>

      <div class='infopage-content-block'>
        <h2>
          Fragen zur Verwaltung der APK-V
        </h2>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block14'] ?? false" aria-controls="expandedBlock14"
                  class='hilfeseite-header' (click)="handleExpandBlock('block14')">
            <h3>
              Wie kann ich die benannten APK-V anzeigen und verwalten?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block14'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block14']">
            <p id="expandedBlock14" aria-hidden="true">
              Um die APK-V in Ihrem Entnahmekrankenhaus zu verwalten, rufen Sie am
              Ende der Benennung über den angezeigten Button die APK-V Liste auf.
              Wenn Sie bereits APK-V benannt haben und sich erneut im EKH-Portal anmelden,
              werden Sie automatisch auf die Liste mit den benannten APK-Vs weitergeleitet.
            </p>
          </ng-container>
        </div>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block15'] ?? false" aria-controls="expandedBlock15"
                  class='hilfeseite-header' (click)="handleExpandBlock('block15')">
            <h3>
              Was bedeuten die verschiedenen Status, die neben den APK-V in der Übersichtsliste
              angezeigt werden?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block15'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block15']">
            <div id="expandedBlock15" aria-hidden="true">
              <p>
                Benannt – die APK-V wurde dem BfArM benannt. Die Freigabe durch das BfArM steht noch
                aus.
              </p>
              <p>
                Freigegeben – die APK-V wurde durch das BfArM freigegeben und kann sich nun im
                Abrufportal registrieren.
              </p>
              <p>
                Aktiv – die APK-V hat sich erfolgreich registriert und kann APKs benennen oder nach
                Erklärungen suchen.
              </p>
              <p>
                Inaktiv – die APK-V wurde durch verantwortliche Person des EKH deaktiviert und kann
                keine
                APKs mehr benennen oder verwalten. Bei der Deaktivierung wird auch die
                Berechtigung zum Abrufen von Erklärungen (APK-Rolle) deaktiviert. Die APK-Rolle kann
                jederzeit durch eine APK-V im Abrufportal wieder aktiviert werden.
              </p>
              <p>
                Gesperrt – das Nutzerkonto wurde durch das BfArM gesperrt und die
                Person hat keinen Zugriff mehr auf das Organspende-Register.
              </p>
            </div>
          </ng-container>
        </div>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block16'] ?? false" aria-controls="expandedBlock16"
                  class='hilfeseite-header' (click)="handleExpandBlock('block16')">
            <h3>
              Wie kann ich weitere APK-V ergänzen?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block16'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block16']">
            <p id="expandedBlock16" aria-hidden="true">
              Sie können jederzeit in der APK-V Liste über den Button "Neu anlegen" weitere APK-V
              benennen.
              Sie können insgesamt bis zu drei APK-V benennen.
              Jede Benennung muss erneut schriftlich an das BfArM übermittelt werden.
            </p>
          </ng-container>
        </div>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block17'] ?? false" aria-controls="expandedBlock17"
                  class='hilfeseite-header' (click)="handleExpandBlock('block17')">
            <h3>
              Wie deaktiviere ich eine APK-V?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block17'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block17']">
            <div id="expandedBlock17" aria-hidden="true">
              <p>
                Sollte die APK-V noch nicht vom Bundesinstitut für Arzneimittel und
                Medizinprodukte (BfArM)
                freigegeben worden sein, wenden Sie sich bitte an
                <a href='mailto:ekh@organspende-register.de' rel='noopener' target='_blank'>
                  ekh&#64;organspende-register.de
                </a>
                und bitten um eine Ablehnung der Freigabe.
                Die benannte APK-V wird dann aus der APK-V Liste gelöscht.
              </p>
              <p>
                Ist die APK-V bereits freigegeben oder aktiv, können Sie die Person selbst in der
                APK-Liste deaktivieren. In jedem Entnahmekrankenhaus muss mindestens eine APK-V
                aktiv sein.
                Möchten Sie die letzte aktive APK-V deaktivieren, müssen Sie zunächst eine neue
                APK-V benennen.
                Erst nach Registrierung der neu benannten APK-V können Sie die bestehende APK-V
                deaktivieren.
              </p>
            </div>
          </ng-container>
        </div>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block18'] ?? false" aria-controls="expandedBlock18"
                  class='hilfeseite-header' (click)="handleExpandBlock('block18')">
            <h3>
              Wie kann ich eine APK-V löschen?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block18'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block18']">
            <p id="expandedBlock18" aria-hidden="true">
              Eine benannte APK-V kann nicht unmittelbar gelöscht werden, sie kann nur
              deaktiviert werden.
              Nach Überschreiten der notwendigen Aufbewahrungsfristen werden deaktivierte
              APK-V durch das System gelöscht.
            </p>
          </ng-container>
        </div>
        <div class='infopage-content-block'>
          <button [attr.aria-expanded]="expandedBlocks['block19'] ?? false" aria-controls="expandedBlock19"
                  class='hilfeseite-header' (click)="handleExpandBlock('block19')">
            <h3>
              Wie kann ich die personenbezogenen Daten einer APK-V ändern?
            </h3>
            <ogr-icon height='10' [icon]="expandedBlocks['block19'] ? 'chevron_up' : 'chevron_down'" color="orange">
            </ogr-icon>
          </button>
          <ng-container *ngIf="expandedBlocks['block19']">
            <p id="expandedBlock19" aria-hidden="true">
              Personenbezogene Daten können erst in einer späteren Ausbaustufe im
              Abrufportal durch den Anwender selbst geändert werden.
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
