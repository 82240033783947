<div class="wizard-container">
  <ul class='wizard-nav-desktop'>
    <li *ngFor='let item of wizardItems; let i = index'>
      <a [routerLink]="'/apkv/naming'" [fragment]="item.routeFragment" *ngIf="currentNavIndex > i; else keinLink"
         [attr.aria-current]='getAriaCurrentValue(i)'
         [id]='"navigation-item-" + i' [class]='getNavClass(i)'>{{item.label}}</a>
      <ng-template #keinLink>
        <span [attr.aria-current]='getAriaCurrentValue(i)' [id]='"navigation-item-" + i'
              [class]='getNavClass(i)'>{{item.label}}</span>
      </ng-template>
    </li>
  </ul>
</div>
