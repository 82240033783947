<header id='header'>
  <div class='header-top-bar'>
    <div class="top-bar-items">
      <div class='top-bar-content'>
        <ng-container *ngIf='loggedIn'>
          <app-countdown
            preLabel='Automatischer Abbruch in' durationMins='15' (countdownFinishFunction)='finishFunction()'></app-countdown>
        </ng-container>
      </div>
      <div class='top-bar-content' *ngIf='showBackLink && loggedIn'>
        <ogr-button [link]="getFormStateURL()" color='text' iconColor='primary' icon='left' id="back-icon-btn">
          <span>Zurück zum Vorgang</span>
        </ogr-button>
      </div>
      <ul class='top-bar-content'>
        <li *ngIf='loggedIn'>
          <ogr-button
            id='checkout-button'
            icon='down'
            iconPosition='suffix'
            iconColor='primary'
            color='text'
            (buttonClick)='onOpenFinishPopup()'
            class='no-bottom-margin'>
            Abmelden
          </ogr-button>
        </li>
      </ul>
    </div>
  </div>
  <div class="header-content">
    <div class="logo-container">
      <picture class="ogr-logo">
        <source media='(min-width:0px)' srcset="assets/images/logos/Logo_Verwaltungsportal_rgb.svg">
        <img alt="Organspenderegister Logo" src="">
      </picture>
      <div class='logo-title'>EKH-Portal</div>
    </div>
  </div>
</header>
