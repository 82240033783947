<div class="confirm-codes-container">
  <h1>
    2. Sichere Bestätigung der Benennung an das Bundesinstitut für Arzneimittel
    und Medizinprodukte (BfArM)
  </h1>
  <ng-container>
    <app-note kind="danger">
      <p>
        Bitte schließen Sie
        <strong>ALLE</strong> Schritte ab (Bestätigung herunterladen,
        Zugangscodes herunterladen, Absenden der Daten) bevor Sie das Fenster
        schließen. Ansonsten werden die
        <strong>Daten nicht gespeichert und gehen verloren!</strong>
      </p>
    </app-note>
  </ng-container>
  <p>
    Es ist erforderlich, dass die benannten Personen durch das BfArM freigegeben
    werden.<strong>
      Dafür muss das Dokument von der Geschäftsführung oder einer offiziellen
      Vertretung der Geschäftsführung an das BfArM übermittelt werden.</strong
    >
  </p>
  <p class="register-quote">Folgende Möglichkeiten des Versandes haben Sie:</p>
  <div class="register-symbols-section">
    <div class="flexbox-column">
      <div class="icon-container">
        <!--HTMl kurz und sauber halten, durch ein leeres alt wird das bild nicht vorgelesen und es wird kein weiteres aria-hidden gebraucht-->
        <img
          id="brief"
          class="illustrations"
          alt=""
          src="./assets/images/logos/Letter.png"
        />
      </div>
      <div class="flexbox-row">
        <img alt="Möglichkeit 1" src="./assets/images/logos/RedA.svg" />
        <p>
          Dokument ausdrucken und mit Unterschrift und Stempel per Post an das
          BfArM senden
        </p>
      </div>
    </div>
    <p class="bold-paragraph">ODER</p>
    <div class="flexbox-column">
      <div class="icon-container">
        <!--HTMl kurz und sauber halten, durch ein leeres alt wird das bild nicht vorgelesen und es wird kein weiteres aria-hidden gebraucht-->
        <img
          id="pdf"
          class="illustrations"
          alt=""
          src="./assets/images/logos/SignPDF.png"
        />
      </div>
      <div class="flexbox-row">
        <img alt="Möglichkeit 2" src="./assets/images/logos/RedB.svg" />
        <p id="moeglichkeit-2">
          PDF digital signieren und siegeln und an
          <a
            href="mailto:ekh@organspende-register.de"
            title="Öffnet Registrierungsemail vom BfArM"
            (click)="countDownStore.resetCounterDuration()"
            >ekh&#64;organspende-register.de</a
          >
          senden
        </p>
      </div>
    </div>
  </div>
  <div class="apkv-naming-end-button-container">
    <ogr-button
      buttonId="download-confirmation-pdf-button"
      class="btn-margin-right"
      [color]="getButtonColor(namingProcessData$ | async)"
      (buttonClick)="handleSubmit()"
      >{{ getButtonLabel(namingProcessData$ | async) }}
    </ogr-button>
    <app-navigation-next-button
      [isNextDisabled]="!isDownloaded(namingProcessData$ | async)"
    ></app-navigation-next-button>
    <div
      class="confirmation-code-spinning-container"
      *ngIf="isLoading(loading$ | async)"
    >
      <app-spinner text="PDF wird heruntergeladen"></app-spinner>
    </div>
  </div>
  <div class="apkv-nav-end-button-container">
    <app-navigation-previous-button></app-navigation-previous-button>
  </div>
</div>
