<div class='apkv-person-container'>
  <h1>1. Verantwortliche Personen (APK-V) benennen</h1>
  <p>
    Bitte geben Sie hier <strong>bis zu drei Personen</strong> an,
    die in Ihrem Entnahmekrankenhaus dafür verantwortlich sind,
    abrufberechtigte Personen (Einsehen der Erklärungen zur Organ- und Gewebespende)
    zu benennen. APK-V sind stets auch abrufberechtigte Personen. <br>
    Beachten Sie bitte: Die von Ihnen zu benennenden Personen (APK-V) benötigen einen elektronischen Heilberufsausweis (eHBA).
  </p>
  <form [formGroup]='personenForm' (ngSubmit)='handleSubmit()'>
    <ng-container *ngFor="let formGroupName of getFormObjectKeys(),let i = index">
      <div [id]='formGroupName' *ngIf="showPersonContainer(formGroupName)">
        <div class='header-container'>
          <h2>Verantwortliche Person {{i + 1}}</h2>
          <div *ngIf="isPersonEntfernenButtonAvailable(i)" class='button-container'>
            <ogr-button [buttonId]='"person-remove-button-" + i' (buttonClick)='handlePersonEntfernenButtonClicked()'
              class='btn-margin-right apkv-person-margin-bottom' icon='delete' iconColor='primary' color='secondary'
              iconPosition='suffix'>
              {{getPersonLabel(i+1)}}
            </ogr-button>
          </div>
        </div>

        <div [formGroupName]='formGroupName' class='apkv-person-formgroup'>
          <div class='apkv-personen-form-row apkv-personen-form-fistrow'>
            <div class='form-element'>
              <label [for]='formGroupName + "-titel"'
                [ngClass]='isFieldInvalid("titel", i + 1) ? "error-message" : ""'>Titel</label>
              <input aria-describedby="titel-error-message" maxlength="35" formControlName='titel' [id]='formGroupName + "-titel"'
                [ngClass]='isFieldInvalid("titel", i + 1) ? "error-input" : ""' [pattern]='REGEX_TITEL' />
              <div *ngIf='isFieldInvalid("titel", i + 1)' class='error-msg'>
                <app-error-message id="titel-error-message" [errorText]='getFieldErrorText("titel", i + 1)'></app-error-message>
              </div>
            </div>

            <p class='second-flex-object'>*Pflichtfeld</p>
          </div>

          <div class='apkv-personen-form-row'>
            <div class='form-element'>
              <label [for]='formGroupName + "-vorname"'
                [ngClass]='isFieldInvalid("vorname", i + 1) ? "error-message" : ""'>Vorname*</label>
              <input aria-describedby="vorname-error-message" maxlength="30" formControlName='vorname' [id]='formGroupName + "-vorname"'
                [ngClass]='isFieldInvalid("vorname", i + 1) ? "error-input" : ""' [pattern]='REGEX_ARZT_NAME' />
              <div *ngIf='isFieldInvalid("vorname", i + 1)' class='error-msg'>
                <app-error-message id="vorname-error-message" [errorText]='getFieldErrorText("vorname", i + 1)'></app-error-message>
              </div>
            </div>
            <div class='form-element'>
              <label [for]='formGroupName + "-nachname"'
                [ngClass]='isFieldInvalid("nachname", i + 1) ? "error-message" : ""'>Nachname*</label>
              <input aria-describedby="nachname-error-message" maxlength="30" formControlName='nachname' [id]='formGroupName + "-nachname"'
                [ngClass]='isFieldInvalid("nachname", i + 1) ? "error-input" : ""' [pattern]='REGEX_NAME' />
              <div *ngIf='isFieldInvalid("nachname", i + 1)' class='error-msg'>
                <app-error-message id="nachname-error-message" [errorText]='getFieldErrorText("nachname", i + 1)'></app-error-message>
              </div>
            </div>
          </div>

          <div class='apkv-personen-form-row'>
            <div class='form-element'>
              <label [for]='formGroupName + "-email"'
                [ngClass]='isFieldInvalid("email", i + 1) ? "error-message" : ""'>Berufliche E-Mail-Adresse*</label>
              <input aria-describedby="email-error-message" type="email" formControlName='email' [id]='formGroupName + "-email"'
                (change)='checkEmailTwiceValidator()' [ngClass]='isEmailFieldInvalid(i+1) ? "error-input" : ""'
                [pattern]='REGEX_EMAIL' />
              <div *ngIf='isEmailFieldInvalid(i+1)' class='error-msg'>
                <app-error-message id="email-error-message" [errorText]='getEmailErrorText(i + 1)'></app-error-message>
              </div>
            </div>

            <div class='form-element'>
              <label [for]='formGroupName + "-telefonnummer"'
                [ngClass]='isFieldInvalid("telefonnummer", i + 1) ? "error-message" : ""'>Telefonnummer</label>
              <input aria-describedby="telefonnummer-error-message" maxlength="16" type="tel" formControlName='telefonnummer' [id]='formGroupName + "-telefonnummer"'
                [ngClass]='isFieldInvalid("telefonnummer", i + 1) ? "error-input" : ""' [pattern]='REGEX_PHONE' />
              <div *ngIf='isFieldInvalid("telefonnummer", i + 1)' class='error-msg'>
                <app-error-message id="telefonnummer-error-message" [errorText]='getFieldErrorText("telefonnummer", i + 1)'></app-error-message>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class='person-button-container'>
      <div class='apkv-person-max-btn-container'>
        <ogr-button (buttonClick)='handleWeiterePersonenButtonClicked()' buttonId='additional-apkv-button'
          color='secondary' icon='add' iconColor='primary' [disabled]='isMaxPersonNumberReached()'>
          Weitere Person benennen
        </ogr-button>

      </div>
      <div class="action-button-container">
        <ogr-button *ngIf="hasFirstAPKV()" class="btn-margin-right" [buttonId]="'wizard-back-button'" (click)='handleCancel()' color='secondary' icon='left'
                    iconColor='primary' iconPosition='prefix'>
          Zurück
        </ogr-button>
        <ogr-button [buttonId]="'wizard-next-button'" (click)='handleSubmit()' color='primary' icon='right'
          iconColor='secondary' iconPosition='suffix' [disabled]='!isPersonenFormGroupValid()'>
          Weiter
        </ogr-button>
      </div>
      <div class='loading-screen'>
        <ng-container *ngIf='(loading$ | async).checkApkvEmails'>
          <app-spinner text='Daten werden geprüft'></app-spinner>
        </ng-container>
      </div>
    </div>
  </form>
</div>
