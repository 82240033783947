import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[libAutoTab]',
})
export class AutoTabDirective {
  @Input() libAutoTab: string;
  @HostListener('input', ['$event.target']) onInput(input): void {
    const length = input.value.length;
    const maxLength = input.attributes.maxlength?.value;

    if (maxLength && length >= maxLength && this.libAutoTab) {
      const field = document.getElementById(
        this.libAutoTab,
      ) as HTMLInputElement;
      if (field) {
        field.focus();
        field.select();
      }
    }
  }
}
