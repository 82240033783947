import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { refreshSession } from '../../../overview/+store/apkv.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CountDownStore {
  private durationSubject = new BehaviorSubject<number>(0);
  public duration$: Observable<number> = this.durationSubject.asObservable();
  durationMins: number;
  startTime: number;

  constructor(private store: Store) {}

  resetCounterDuration(): void {
    const duration = this.durationMins * 60;
    this.setStartTime(new Date().getTime());
    this.durationSubject.next(duration);
    this.store.dispatch(refreshSession());
  }

  getDuration(): Observable<number> {
    return this.duration$;
  }

  getStartTime(): number {
    return this.startTime;
  }

  setStartTime(startTime: number): void {
    this.startTime = startTime;
  }

  setDuration(): void {
    this.durationSubject.next(this.durationMins * 60);
  }

  updateDuration(duration: number): void {
    this.durationSubject.next(duration);
  }

  setDurationMins(durationMins: number): void {
    this.durationMins = durationMins;
  }
}
