<div class="apkv-not-found main-content margin-top-80">
  <div class="apkv-not-found image-1">
    <!--HTMl kurz und sauber halten, durch ein leeres alt wird das bild nicht vorgelesen und es wird kein weiteres aria-hidden gebraucht-->
    <img class="apkv-not-found image-adjustment" src="assets/images/logos/404_Page_Not_Found_Isometric.svg" alt="">
  </div>
  <div class="margin-top-30">
    <h1 class="apkv-not-found text-adjustment">Seite nicht gefunden</h1>
  </div>
  <div class="apkv-not-found description margin-top-30 text-align-center">
    <p>Überprüfen Sie die <strong>Schreibweise der URL</strong> oder kehren Sie zurück zur Startseite.</p>
  </div>
  <div class="apkv-not-found button-adjustment margin-top-30">
    <app-link-button [label]="buttonName" routerLink=""></app-link-button>
  </div>
</div>
