import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-apkv-access-list',
  templateUrl: './apkv-access-list.component.html',
  styleUrls: ['./apkv-access-list.component.scss'],
})
export class ApkvAccessListComponent {
  @Input() apkvDataList;

  getNameByApkvData(apkv): string {
    if (!apkv) {
      return '';
    }
    return `${apkv.titel ? apkv.titel + ' ' : ''}${apkv.vorname} ${
      apkv.nachname
    }`;
  }
}
