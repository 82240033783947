import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconColor, IconType } from '../icon/icon.component';

@Component({
  selector: 'ogr-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() buttonId: string;
  @Input() color: 'primary' | 'secondary' | 'text' = 'primary';
  @Input() type = 'button';
  @Input() icon: IconType;
  @Input() iconPosition: 'prefix' | 'suffix' = 'prefix';
  @Input() iconSize = 16;
  @Input() iconColor: IconColor;
  @Input() iconOnly = false;
  @Input() disabled = false;
  @Input() link: string;
  @Input() fragment: string;
  @Input() skipLocationChange = false;
  @Input() ariaLabel: string;

  @Output() buttonClick = new EventEmitter<boolean>();

  click = () => {
    this.buttonClick.emit(true);
  };
}
