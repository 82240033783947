import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CountDownStore } from './countdown.store';
import { PopupService } from '../popup/popup.store';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() durationMins: number = null;
  @Input() route: string;
  @Output() countdownFinishFunction: EventEmitter<void> =
    new EventEmitter<void>();
  @Input() preLabel: string;
  duration: number;
  subscription = new Subscription();
  timer: number;
  startDuration: number;

  constructor(
    public utilsService: UtilsService,
    public countDownStore: CountDownStore,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {
    this.countDownStore.setDurationMins(this.durationMins);
    this.countDownStore.setDuration();
    this.startDuration = this.durationMins * 60;
    this.subscription.add(
      this.countDownStore.getDuration().subscribe((duration) => {
        this.duration = duration;
        if (duration <= 30 && !this.popupService.isPopupOpen) {
          this.popupService.setPopupConfig({
            header: 'Ihre Sitzung läuft in Kürze ab.',
            content:
              'Wenn Sie keine Eingabe vornehmen, wird ihre aktuelle Sitzung innerhalb der nächsten 30 Sekunden beendet. Nicht gespeicherte Daten können verloren gehen. Sie können die Sitzung verlängern oder direkt beenden.',
            buttonLabel: 'Sitzung verlängern',
            closeLabel: 'Sitzung beenden',
            serviceName: 'countdownStore',
            serviceFunction: 'resetCounterDuration',
            serviceCloseName: 'apkvStore',
            serviceCloseFunction: 'navigateToLoggedOut',
            cancelButtonSize: '240px',
            functionButtonSize: '240px',
            show: true,
          });
        }
      }),
    );
    this.startCountdown();
  }

  startCountdown(): void {
    const now = new Date().getTime();
    this.countDownStore.setStartTime(now);
    if (this.duration && this.duration > 0) {
      this.timer = setTimeout(() => {
        this.doCountdown();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearTimeout(this.timer);
    this.popupService.setPopupConfig({ show: false });
  }

  doCountdown(): void {
    const startTime = this.countDownStore.getStartTime();
    const now = new Date().getTime();
    const countDowntime = Math.round((now - startTime) / 1000);
    const secondsLeft = this.startDuration - countDowntime;
    if (secondsLeft > 0) {
      this.countDownStore.updateDuration(secondsLeft);
      setTimeout(() => {
        this.doCountdown();
      }, 1000);
    } else {
      this.countdownFinishFunction.emit();
    }
  }
}
