<div class='welcome-container'>
    <h1>Willkommen im EKH-Portal des Organspende-Registers</h1>
    <p class='welcome-info-text'>
        Hier können Sie verantwortliche Personen (APK-V) für Ihr Entnahmekrankenhaus (EKH) benennen und verwalten,
        die für die Benennung von abrufberechtigten Personen in Ihrem Krankenhaus (APK) zuständig sind.
        Somit kann in Ihrem Krankenhaus nach der Bereitschaft zur Organ- und Gewebespende eines Patienten bzw.
        einer Patientin gesucht werden.
    </p>
    <p class='welcome-logo-head'>Ein Portal des Bundesinstituts für Arzneimittel und Medizinprodukte</p>
    <div class='welcome-footer-logo'>
        <a href='https://www.bfarm.de/' target='_blank' aria-label='Weiterleitung zur Webseite der Bundesinstitut für Arzneimittel und Medizinprodukte' rel='noopener'>
            <picture class="ogr-logo">
            <source media='(min-width:0px)' srcset='assets/images/logos/BfArM_RGB_de.svg'>
            <img alt='Bundesinstitut für Arzneimittel und Medizinprodukte Logo' src=''>
            </picture>
        </a>
    </div>
</div>
