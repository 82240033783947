<footer id="footer">
  <div class="footer-nav-container" [class.login-page]="onLoginPage()">
    <ul class="footer-navlist">
      <ng-container *ngFor="let pageLink of infoPageMenus">
        <li *ngIf="hasPageStates(pageLink.requiredStates)">
          <ng-template [ngIf]="pageLink.isAbsolute" [ngIfElse]="angularLink">
            <a [href]="renderUrl(pageLink)">{{pageLink.label}}</a>
          </ng-template>
          <ng-template #angularLink>
            <a [class.footer-underline]="isCurrentPage(pageLink)"
               [attr.aria-current]="isCurrentPage(pageLink) ? 'page' : 'false'"
               [routerLink]="pageLink.link" (click)='$event.preventDefault(onNavigate(pageLink))'>
              {{pageLink.label}}
            </a>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class='footer-bottom-container' [class.login-page]="onLoginPage()">
    <div class="footer-logo">
      <a href="https://www.bfarm.de/" target="_blank"
         aria-label="Weiterleitung zur Webseite der Bundesinstitut für Arzneimittel und Medizinprodukte" rel='noopener'>
        <picture class="ogr-logo">
          <source media='(min-width:0px)' srcset="assets/images/logos/BfArM_RGB_de.svg">
          <img alt="Bundesinstitut für Arzneimittel und Medizinprodukte Logo" src="">
        </picture>
      </a>
    </div>
  </div>
</footer>
