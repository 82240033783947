import { FormGroup, ValidatorFn } from '@angular/forms';

export const OneFilledOutValidator = (): ValidatorFn => {
  return (group: FormGroup): { [key: string]: boolean } => {
    const fields = [];
    for (const field in group.controls) {
      if (group.controls.hasOwnProperty(field)) {
        fields.push(group.get(field).value);
      }
    }
    const result = fields.filter((field) => !!field);
    const valid = result.length !== 0;
    return valid
      ? null
      : {
          noOneFilledOut: true,
        };
  };
};
