<div class='infopage-container'>
  <div class='infopage-content content-layout'>
    <div class='top-bar-content btn-margin-button' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left' >
        <span>Zurück</span>
      </ogr-button>
    </div>
    <h1>Datenschutz</h1>
    <h2>
      1. Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer
      nationaler Datenschutzgesetze der Mitgliedsstaaten
      sowie sonstiger datenschutzrechtlicher Bestimmungen
    </h2>
    <div class='infopage-content-block flexed-block'>
      <p class='no-padding'>Bundesinstitut für Arzneimittel und Medizinprodukte</p>
      <p class='no-padding'>Kurt-Georg-Kiesinger-Allee 3</p>
      <p class='no-padding'>53175 Bonn</p>
    </div>
    <div class='infopage-content-block flexed-block'>
      <span class='flexed-info-row'>
        <p>Telefon:</p>
        <p>+49 (0)228 99 307-0</p>
      </span>
      <span class='flexed-info-row'>
        <p>Telefax:</p>
        <p>+49 (0)228 99 307-5207</p>
      </span>
      <span class='flexed-info-row'>
        <p>E-Mail:</p>
        <a href='mailto:poststelle@bfarm.de' rel='noopener' target='_blank'>poststelle&#64;bfarm.de</a>
      </span>
      <span class='flexed-info-row'>
        <p>Website:</p>
        <a href='https://www.bfarm.de' rel='noopener' target='_blank'>
          www.bfarm.de
          <ogr-icon class="icon-linkextern" icon="linkextern" color="primary"></ogr-icon>
        </a>
      </span>
    </div>
    <div class='infopage-content-block'>
      <p>
        Das Bundesinstitut für Arzneimittel und Medizinprodukte (BfArM)
        unterliegt als selbständige Bundesoberbehörde im Geschäftsbereich des Bundesministeriums
        für Gesundheit (BMG)
        den Bestimmungen der Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes (BDSG).
      </p>
    </div>
    <h2>
      2. Der Datenschutzbeauftragte des Verantwortlichen
    </h2>
    <div class='infopage-content-block flexed-block'>
      <p class='no-padding'>Arian Mehrpuyan</p>
      <span class='flexed-info-row'>
        <p>
          E-Mail:
        </p>
        <a href='mailto:datenschutz@bfarm.de' rel='noopener' target='_blank'>
          datenschutz&#64;bfarm.de
        </a>
      </span>
    </div>
    <h2>
      3. Allgemeines zur Datenverarbeitung
    </h2>
    <h3>
      3.1. Umfang der Verarbeitung personenbezogener Daten
    </h3>
    <div class='infopage-content-block'>
      <p>
        Wir erheben und verwenden personenbezogene Daten der Nutzenden grundsätzlich nur,
        soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen
        erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten der Nutzenden erfolgt regelmäßig
        nur nach deren Einwilligung. Ausnahmen gelten in solchen Fällen,
        in denen die vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich,
        die Verarbeitung zur Bereitstellung der Website technisch notwendig oder die Verarbeitung der
        Daten durch gesetzliche Vorschriften gestattet ist.
      </p>
      <p>
        Soweit zur Wahrnehmung unserer Leistungen eine weitergehende Verarbeitung von
        personenbezogenen Daten erforderlich ist, wird an entsprechender Stelle
        darüber informiert und auf den zugehörigen Abschnitt in dieser Erklärung verwiesen.
      </p>
    </div>
    <h3>
      3.2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
    </h3>
    <div class='infopage-content-block'>
      <p>
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
        Einwilligung der betroffenen Person einholen, dient Artikel 6 Absatz 1 Buchstabe a der
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten,
        die zur Erfüllung eines Vertrages erforderlich ist, dessen Vertragspartei die betroffene Person ist,
        dient Artikel 6 Absatz 1 Buchstabe b der DSGVO als Rechtsgrundlage.
        Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung rechtlicher Verpflichtungen erforderlich ist,
        welchen das BfArM unterliegt, dient Artikel 6 Absatz 1 Buchstabe c der DSGVO als Rechtsgrundlage.
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
        Person eine Verarbeitung personenbezogener Daten erforderlich machen,
        dient Artikel 6 Absatz 1 Buchstabe d der DSGVO als Rechtsgrundlage.
      </p>
      <p>
        Erfolgt die Verarbeitung personenbezogener Daten im Rahmen der Wahrnehmung von Aufgaben,
        welche im öffentlichen Interesse liegen oder in Ausübung öffentlicher Gewalt,
        die dem BfArM als Verantwortlichen übertragen wurde,
        dient Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit § 1 Absatz 1 und 3, § 4 Absatz 1 und 4
        des Gesetzes über Nachfolgeeinrichtungen des Bundesgesundheitsamtes (BGA-NachfG) in
        Verbindung mit § 77 Absatz 1 Arzneimittelgesetz (AMG) als Rechtsgrundlage.
        Die gesetzliche Aufgabenübertragung, welche uns in Verbindung mit Artikel 6 Absatz 1 Buchstabe e
        DSGVO unter anderem als Rechtsgrundlage für die Verarbeitung personenbezogener Daten dient,
        findet auf nationaler Ebene konkrete Ausgestaltung in einigen Spezialgesetzen. Neben dem bereits genannten
        AMG sind dies beispielsweise auch das Betäubungsmittelgesetz (BtMG), das Medizinproduktegesetz (MPG),
        die Medizinprodukte-Sicherheitsplanverordnung (MPSV) und das Grundstoffüberwachungsgesetz (GÜG).
        Diese Rechtsgrundlagen gelten insbesondere auch bei der Verarbeitung personenbezogener
        Daten im Zusammenhang mit den auf dieser Internetseite zur Verfügung gestellten Formularen.
      </p>
    </div>
    <h3>
      3.3. Datenlöschung und Speicherdauer
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder bis zu ihrer Löschung gesperrt,
        sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen,
        wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen,
        Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
        Eine Sperrung oder Löschung der Daten erfolgt auch dann,
        wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
        es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss
        oder eine Vertragserfüllung besteht.
      </p>
    </div>
    <h2>
      4. Bereitstellung der Website und Erstellung von Logfiles
    </h2>
    <h3>
      4.1. Beschreibung und Umfang der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Bei jedem Aufruf unserer Internetseite erfasst und verarbeitet unser System automatisiert
        Daten und Informationen vom Computersystem des aufrufenden Rechners.
      </p>
      <p>
        Folgende Daten werden hierbei erhoben:
      </p>
      <ul>
        <li>
          Informationen über den Browsertyp und die verwendete Version
        </li>
        <li>
          Betriebssystem des auf die Seite zugreifenden Gerätes
        </li>
        <li>
          IP-Adresse
        </li>
        <li>
          Datum und Uhrzeit des Zugriffs
        </li>
        <li>
          Websites, von denen das System der Nutzenden auf unsere Internetseite gelangt
        </li>
        <li>
          aufgerufene Seite/Name der abgerufenen Datei
        </li>
        <li>
          übertragene Datenmenge
        </li>
        <li>
          Meldung, ob der Zugriff/Abruf erfolgreich war
        </li>
        <li>
          Website, auf welche Sie nach Ihrem Besuch auf unserer Website gehen
        </li>
      </ul>
      <p>
        Die genannten Daten werden in den Logfiles unseres Systems auch gespeichert.
        Während der Dauer Ihres Besuchs auf unserer Website wird Ihre IP-Adresse zur Auslieferung der
        Daten an Ihren Rechner verarbeitet. Im Anschluss wird die IP-Adresse um zwei Bytes
        gekürzt (Bsp.: 192.168.xxx.xxx) und in dieser Form gespeichert.
        Auf diese Weise ist eine Zuordnung der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr möglich.
        Andere Daten, die eine Zuordnung der Daten zu Nutzenden ermöglichen, werden nicht gespeichert.
        Eine Speicherung der genannten Daten zusammen mit anderen personenbezogenen Daten der Nutzenden findet ebenfalls
        nicht statt.
      </p>
    </div>
    <h3>
      4.2. Rechtsgrundlage für die Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Artikel 6 Absatz 1 Buchstabe e
        DSGVO in Verbindung mit § 1 Absatz 1 und 3, § 4 Absatz 1 und 4 BGA-NachfG in Verbindung mit § 77 Absatz 1 AMG.
      </p>
    </div>
    <h3>
      4.3. Zweck der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig,
        um eine Auslieferung der Website an die Rechner der Nutzenden zu ermöglichen.
        Hierfür muss die IP-Adresse der Nutzenden für die Dauer der Sitzung gespeichert bleiben.
        Die anschließende Speicherung der anonymisierten IP-Adresse dient dem Schutz unserer informationstechnischen
        Systeme vor Hackerangriffen sowie der Optimierung unseres Internetangebots.
      </p>
    </div>
    <h3>
      4.4. Dauer der Speicherung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
        sind.
        Im Falle der Erfassung von personenbezogenen oder personenbeziehbaren Daten zur Bereitstellung der
        Website ist dies der Fall, sobald die jeweilige Sitzung beendet ist.
        Die im Logfile gespeicherten Daten sind den Nutzenden nicht mehr zuzuordnen.
        Sie werden für ein Jahr gespeichert.
        Eine Weitergabe der Daten an Dritte oder eine anderweitige Verwendung findet nicht statt.
      </p>
    </div>
    <h3>
      4.5. Widerspruchs- und Beseitigungsmöglichkeit
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der
        Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich.
        Es besteht folglich seitens der Nutzenden keine Widerspruchsmöglichkeit.
        Da die Speicherung der Daten in den Logfiles ohne Personenbezug erfolgt, ist ein Widerspruch nicht möglich.
      </p>
    </div>
    <h2>
      5. E-Mail-Kontakt
    </h2>
    <h3>
      5.1. Beschreibung und Umfang der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Es ist eine Kontaktaufnahme über die im Impressum bereitgestellte E-Mail-Adresse möglich.
        In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten der Nutzenden gespeichert.
        Die Speicherung soll es ermöglichen im Rahmen der Bearbeitung von Anliegen
        erforderlichenfalls mit den Nutzenden in Kontakt zu treten.
      </p>
      <p>
        Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
        Die Daten werden ausschließlich für die Verarbeitung der Konversationen verwendet.
      </p>
    </div>
    <h3>
      5.2. Rechtsgrundlage der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Rechtsgrundlage für die Verarbeitung der Daten,
        die im Zuge einer Übersendung einer E-Mail übermittelt werden,
        ist Artikel 6 Absatz 1 Buchstabe e DSGVO in Verbindung mit § 1 Absatz 1 und 3, § 4 Absatz 1 und 4
        BGA-NachfG in Verbindung mit § 77 Absatz 1 AMG.
      </p>
    </div>
    <h3>
      5.3. Zweck der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Im Falle einer Kontaktaufnahme per
        E-Mail dient die Speicherung der E-Mail-Adresse dazu Absendern antworten zu können.
      </p>
      <p>
        Die Angabe der Anschrift wird erst dann zwingend erforderlich,
        wenn die Anfragen von Nutzenden Gebühren verursachen.
        Dies kann im Falle von Anfragen nach dem Informationsfreiheitsgesetz (IFG) der Fall sein.
        Sollte eine Anfrage Gebühren verursachen, werden Nutzende darauf vor Beginn der Bearbeitung aufmerksam
        gemacht und um Zustimmung gebeten. Bleibt die Bestätigung des Anliegens innerhalb der im Anschreiben gesetzten
        Frist aus, werden die erhobenen personenbezogenen Daten zusammen mit der Anfrage gelöscht.
        Mit der Zustimmung wird zugleich um die Angabe Ihrer postalischen Anschrift gebeten,
        falls diese noch nicht mitgeteilt wurde. Dadurch wird die Zustellung eines schriftlichen Gebührenbescheids
        ermöglicht.
        Die mögliche Angabe der Telefonnummer hilft ausschließlich bei der schnelleren Bearbeitung des Anliegens.
        Sollten Unklarheiten bezüglich der an uns gerichteten Fragen bestehen,
        wäre dadurch eine schnellere und unkomplizierte Rücksprache mit den Kontaktsuchenden möglich.
      </p>
    </div>
    <h3>
      5.4. Dauer der Speicherung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer
        Erhebung nicht mehr erforderlich sind und Vorschriften zur Aufbewahrung,
        insbesondere die „Registraturrichtlinie für das Bearbeiten und Verwalten von Schriftgut in Bundesministerien“,
        welche in Bundesbehörden analog zur Anwendung kommt, nicht mehr entgegenstehen.
        Nach dieser werden die eingegebenen personenbezogenen Daten und Konversationen für einen Zeitraum von 10 Jahren
        zu den genannten Zwecken gespeichert.
        Sie werden nicht an Dritte weitergegeben.
      </p>
    </div>
    <h3>
      5.5. Widerspruchs- und Beseitigungsmöglichkeit
    </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende haben jederzeit die Möglichkeit, ihre Einwilligung zur Verarbeitung der personenbezogenen Daten zu
        widerrufen.
        Erfolgte die Kontaktaufnahme per E-Mail,
        so können Nutzende der Speicherung ihrer personenbezogenen Daten jederzeit widersprechen.
        Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden,
        werden in diesem Fall gelöscht. In einem solchen Fall kann die Konversation nicht fortgeführt werden.
      </p>
    </div>
    <h2>
      6. Funktionen und Angebote der Website
    </h2>
    <div class='infopage-content-block'>
      <p>
        Über unsere Internetseite haben Sie Zugriff auf verschiedene Funktionen und Angebote.
        Soweit dort Daten verarbeitet werden, weisen die Funktionen und Angebote entweder eine eigene
        Datenschutzerklärung auf oder verweisen auf diesen Abschnitt der allgemeinen Datenschutzerklärung,
        welcher die Besonderheiten der Verarbeitung personenbezogener Daten in diesen Funktionen und Angeboten
        erläutert.
      </p>
    </div>
    <h3>6.1. Beschreibung und Umfang der Datenverarbeitung</h3>
    <div class='infopage-content-block'>
      <p>
        Auf unserer Internetseite befindet sich ein Portal, über welches die im Entnahmekrankenhaus
          <strong>(EKH)</strong> zum Abruf von Erklärungen zur Organ- oder Gewebespende berechtigten Personen
          <strong>(APK)</strong> benannt oder gewechselt werden können. Ebenso können darüber die für die Verwaltung der
          <strong>APK</strong> verantwortlichen Personen
          <strong>(APK-V)</strong> verwaltet werden. Machen Nutzende von der Möglichkeit Gebrauch sich dort anzumelden und Benennungen oder Wechsel von
          <strong>APK-V</strong> vorzunehmen, so werden die in die dortigen Formulare eingegebenen Daten an uns übermittelt und von uns verarbeitet.
      </p>
      <p>Folgende Daten können eingegeben werden und werden verarbeitet:</p>
      <ul>
        <li>
          Anmeldung <strong>EKH</strong>-Portal
          <ul><li>Zugangscode EKH</li></ul>
        </li>
        <li>
          <strong>APK-V</strong> benennen
          <ul>
            <li>Titel</li>
            <li>Vornamen</li>
            <li>Familiennamen</li>
            <li>Berufliche E-Mail-Adresse</li>
            <li>Telefonnummer</li>
          </ul>
        </li>

      </ul>

      <p>
        Eine Übermittlung der Daten vom BfArM an Dritte findet nicht statt.
      </p>
    </div>

    <h3>
      6.2. Rechtsgrundlage der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Rechtsgrundlage für die Verarbeitung der Daten ist Artikel 6 Absatz
        1 Buchstaben e und c DSGVO (Aufgabe im öffentlichen Interesse, rechtliche
         Verpflichtung) i.V.m. dem § 2a TPG (Gesetz über die Spende,
        Entnahme und Übertragung von Organen und Geweben - Transplantationsgesetz).
      </p>
    </div>
    <h3>
      6.3. Zweck der Datenverarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die genannten Daten werden verarbeitet, um den Benennungs- oder Wechselprozess von
        APK-V durchführen und dies sicher bestätigen lassen zu können.
      </p>
    </div>
    <h3>
      6.4. Dauer der Speicherung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind und soweit Vorschriften
        zur Aufbewahrung dem nicht mehr entgegenstehen. Die Löschung erfolgt
        maximal 10 Jahre nach der Erhebung,
        letzten Veränderung oder Deaktivierung des Datensatzes.
      </p>
    </div>
    <h3>
      6.5. Widerspruchs- und Beseitigungsmöglichkeit
    </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende haben jederzeit die Möglichkeit, der Verarbeitung ihrer personenbezogenen Daten zu widersprechen.
        Der Widerspruch zur Datenverarbeitung führt auf Grund entsprechender rechtlicher
        Vorgaben zunächst zur sechsjährigen Deaktivierung und erst nach 10 Jahren zur vollständigen Löschung.
      </p>
    </div>
    <h2>
      7. Rechte der betroffenen Person
    </h2>
    <div class='infopage-content-block'>
      <p>
        Werden personenbezogene Daten von Nutzenden verarbeitet, sind diese Betroffene im Sinne der DSGVO.
        Nutzenden unseres Internetauftritts stehen daher folgende Rechte gegenüber dem BfArM als Verantwortlichen zu:
      </p>
    </div>
    <h3>
      7.1. Auskunftsrecht
    </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende können von uns als dem Verantwortlichen eine Bestätigung darüber verlangen,
        ob personenbezogene Daten, die sie betreffen, von uns verarbeitet werden.
      </p>
      <p>
        Liegt eine solche Verarbeitung vor,
        können Nutzende von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
      </p>
      <ul>
        <li>
          die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
        </li>
        <li>
          die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
        </li>
        <li>
          die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die sie betreffende personenbezogene
          Daten offengelegt wurden oder noch offengelegt werden;
        </li>
        <li>
          die geplante Dauer der Speicherung der die Nutzenden betreffenden personenbezogenen Daten oder,
          falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
        </li>
        <li>
          das Bestehen eines Rechts auf Berichtigung oder Löschung der die Nutzenden betreffenden personenbezogenen
          Daten,
          eines Rechts auf
          Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese
          Verarbeitung;
        </li>
        <li>
          das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
        </li>
        <li>
          alle verfügbaren Informationen über die Herkunft der Daten,
          wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
        </li>
        <li>
          das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel
          22 Absatz 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen
          über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer
          derartigen Verarbeitung für die betroffene Person.
        </li>
      </ul>
      <p>
        Den Nutzenden steht das Recht zu, Auskunft darüber zu verlangen,
        ob die sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation
        übermittelt werden. In diesem Zusammenhang können sie verlangen,
        über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
        werden.
      </p>
      <p>
        Dieses Auskunftsrecht kann insoweit beschränkt werden,
        als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich
        macht oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder
        Statistikzwecke notwendig ist.
      </p>
    </div>
    <h3>
      7.2. Recht auf Berichtigung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen,
        sofern die verarbeiteten personenbezogenen Daten, die sie betreffen, unrichtig oder unvollständig sind.
        Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
      </p>
      <p>
        Das Recht auf Berichtigung kann insoweit beschränkt werden,
        als es voraussichtlich die Verwirklichung der Forschungs-
        oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
        die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.
      </p>
    </div>
    <h3>
      7.3. Recht auf Einschränkung der Verarbeitung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Unter den folgenden Voraussetzungen können Nutzende die
        Einschränkung der Verarbeitung der sie betreffenden personenbezogenen Daten verlangen:
      </p>
      <ul>
        <li>
          wenn Nutzende die Richtigkeit der sie betreffenden personenbezogenen für eine Dauer bestreiten,
          die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
        </li>
        <li>
          die Verarbeitung unrechtmäßig ist und sie die Löschung der personenbezogenen
          Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
        </li>
        <li>
          der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt,
          die Nutzenden diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
        </li>
        <li>
          wenn Nutzende Widerspruch gegen die Verarbeitung gemäß Artikel
          21 Absatz 1 DSGVO eingelegt haben und noch nicht feststeht,
          ob die berechtigten Gründe des Verantwortlichen gegenüber ihren Gründen überwiegen.
        </li>
      </ul>
      <p>
        Wurde die Verarbeitung der die Nutzenden betreffenden personenbezogenen Daten eingeschränkt,
        dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Einwilligung der
        Nutzenden oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person
        oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <p>
        Wurde die Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen eingeschränkt,
        werden Nutzende von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
      </p>
      <p>
        Das Recht der Nutzenden auf Einschränkung der Verarbeitung kann seinerseits insoweit beschränkt werden,
        als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht
        oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs-
        oder Statistikzwecke notwendig ist.
      </p>
    </div>
    <h3>
      7.4. Recht auf Löschung
    </h3>
    <h4>1. Löschungspflicht</h4>
    <div class='infopage-content-block'>
      <p>
        Nutzende können von dem Verantwortlichen verlangen, dass die sie betreffenden personenbezogenen
        Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet,
        diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
      </p>
      <ul>
        <li>
          Die Nutzende betreffenden personenbezogenen Daten sind für die Zwecke,
          für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
        </li>
        <li>
          Nutzende widerrufen ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz 1
          Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte,
          und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
        </li>
        <li>
          Nutzende legen gemäß Artikel 21 Absatz 1 DSGVO Widerspruch gegen die Verarbeitung ein
          und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor,
          oder sie legen gemäß Artikel 21 Absatz 2 DSGVO Widerspruch gegen die Verarbeitung ein.
        </li>
        <li>
          Die Nutzende betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
        </li>
        <li>
          Die Löschung der Nutzende betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
          Verpflichtung
          nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
        </li>
        <li>
          Die Nutzende betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
          Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO erhoben.
        </li>
      </ul>
    </div>
    <h4>2. Information an Dritte</h4>
    <div class='infopage-content-block'>
      <p>
        Hat der Verantwortliche die Nutzende betreffenden personenbezogenen
        Daten öffentlich gemacht und ist er gemäß Artikel 17 Absatz 1 DSGVO zu deren
        Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren
        Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art,
        um für die Datenverarbeitung Verantwortliche, welche die personenbezogenen Daten
        verarbeiten, darüber zu informieren, dass Nutzende als betroffene Person von ihnen
        die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien
        oder Replikationen dieser personenbezogenen Daten verlangt haben.
      </p>
    </div>
    <h4>3. Ausnahmen</h4>
    <div class='infopage-content-block'>
      <p>
        Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
      </p>
      <ul>
        <li>
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
        </li>
        <li>
          zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der
          Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
          Aufgabe,
          die im öffentlichen Interesse
          liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
        </li>
        <li>
          aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel
          9 Absatz 2 Buchstabe h und i sowie Artikel 9 Absatz 3 DSGVO;
        </li>
        <li>
          für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke
          oder für statistische Zwecke gemäß Artikel 89 Absatz 1 DSGVO, soweit das unter Abschnitt a)
          genannte Recht voraussichtlich die
          Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
        </li>
        <li>
          zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </li>
      </ul>
    </div>
    <h3>
      7.5. Recht auf Unterrichtung
    </h3>
    <div class='infopage-content-block'>
      <p>
        Haben Nutzende das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
        dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern,
        denen die sie betreffenden personenbezogenen Daten offengelegt wurden,
        diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
        es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
        Nutzenden steht gegenüber dem Verantwortlichen das Recht zu,
        über diese Empfänger unterrichtet zu werden.
      </p>
    </div>
    <h3>
      7.6. Recht auf Datenübertragbarkeit
    </h3>
    <div class='infopage-content-block'>
      <p>
        Die Nutzenden haben das Recht, die sie betreffenden personenbezogenen Daten,
        die sie dem Verantwortlichen bereitgestellt haben,
        in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
        Außerdem haben die Nutzenden das Recht diese Daten einem anderen Verantwortlichen
        ohne Behinderung durch den Verantwortlichen,
        dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
      </p>
      <ul>
        <li>
          die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a
          DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO
          oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b DSGVO beruht und
        </li>
        <li>
          die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </li>
      </ul>
      <p>
        In Ausübung dieses Rechts haben Nutzende ferner das Recht, zu erwirken,
        dass die sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen
        Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
        Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
        Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt
        oder in Ausübung öffentlicher Gewalt erfolgt,
        die dem Verantwortlichen übertragen wurde.
      </p>
    </div>
    <h3>
      7.7. Widerspruchsrecht
    </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
        jederzeit gegen die Verarbeitung der sie betreffenden personenbezogenen Daten,
        die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO erfolgt, Widerspruch einzulegen;
        dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
      </p>
      <p>
        Der Verantwortliche verarbeitet die Nutzende betreffenden personenbezogenen Daten nicht mehr, es sei denn,
        er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die ihre Interessen,
        Rechte und Freiheiten überwiegen,
        oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <p>
        Nutzende haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
        ungeachtet der Richtlinie 2002/58/EG – ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben,
        bei denen technische Spezifikationen verwendet werden.
      </p>
      <p>
        Nutzende haben auch das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
        bei der Verarbeitung sie betreffender personenbezogener Daten,
        die zu wissenschaftlichen oder historischen Forschungszwecken
        oder zu statistischen Zwecken gemäß Artikel 89 Absatz 1 DSGVO erfolgt, dieser zu widersprechen.
      </p>
      <p>
        Das Widerspruchsrecht der Nutzenden kann insoweit beschränkt werden,
        als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht
        oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs-
        oder Statistikzwecke notwendig ist.
      </p>
    </div>
      <h3>
        7.8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </h3>
    <div class='infopage-content-block'>
      <p>
        Nutzende haben das Recht, ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
        Durch den Widerruf der Einwilligung
        wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
      </p>
    </div>
    <h3>
      7.9. Recht auf Beschwerde bei einer Aufsichtsbehörde
    </h3>
    <div class='infopage-content-block'>
      <p>
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht
        Nutzenden das Recht auf Beschwerde bei einer Aufsichtsbehörde,
        insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
        ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu, wenn sie der Ansicht sind,
        dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
      </p>
      <p>
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
        unterrichtet den Beschwerdeführer über den Stand und die
        Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
        gerichtlichen Rechtsbehelfs nach Artikel 78 DSGVO.
      </p>
    </div>
    <h2>
      8. Gültigkeit und Änderung der Datenschutzerklärung
    </h2>
    <div class='infopage-content-block'>
      <p>
        Die Datenschutzerklärung hat den Stand 08/2022. Wir behalten uns das Recht vor,
        diese Datenschutzbestimmungen jederzeit mit Wirkung für die Zukunft DSGVO-konform zu ändern.
        Eine jeweils aktuelle Version ist auf der Website verfügbar.
      </p>
    </div>
    <div class='top-bar-content' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left' >
        <span>Zurück</span>
      </ogr-button>
    </div>
  </div>
</div>
