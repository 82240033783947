<svg
    [class]="getIconColorString()"
    [attr.width]="getIconSize(width, size)"
    [attr.height]="getIconSize(height, size)"
    [id]="icon"
    aria-hidden="true"
    focusable="false"
>
  <use [attr.xlink:href]="'assets/icons/' + icon + '.svg#' + icon"></use>
</svg>
