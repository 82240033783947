import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const accessCodeValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const code1 = control.get('code1');
  const code2 = control.get('code2');
  const code3 = control.get('code3');
  const code4 = control.get('code4');

  return code1.invalid || code2.invalid || code3.invalid || code4.invalid
    ? { accessCodeInvalid: true }
    : null;
};
