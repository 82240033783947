import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { REGEX_ARZT_NAME } from '../_regex/arzt-name-regex';

export function ArztNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasValidChars = REGEX_ARZT_NAME.test(value);
    return !hasValidChars ? { arztNameInvalid: true } : null;
  };
}
