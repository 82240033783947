import { createAction, props } from '@ngrx/store';
import {
  ApkvListResponse,
  ApkvPersonModel,
  PeronFormApkvObject,
  PersonFormObject,
} from '../apkv.model';
import { PdfTypesEnum } from '../../shared';

export enum apkvActionTypes {
  CHECK_CODE = '[CHECK_CODE] Check Code',
  CHECK_CODE_SUCCESS = '[CHECK_CODE] Check Code Success',
  CHECK_CODE_FAILED = '[CHECK_CODE] Check Code Failed',
  RESET_CODE_VALIDITY_OBJECT = '[CHECK_CODE] Reset Code Validity Object',
  SET_CODE_VALIDITY_OBJECT = '[CHECK_CODE] Set Code Validity Object',
  RETRIEVE_APKVS = '[APKV] Retrieve Apkvs Status',
  RETRIEVE_APKVS_SUCCESS = '[APKV] Retrieve Apkvs Status Success',
  RETRIEVE_APKVS_FAILED = '[APKV] Retrieve Apkvs Status Failed',
  SET_ACTIVE_APKV = '[APKV] Set active APKV',
  RESET_ACTIVE_APKV = '[APKV] Reset active APKV',
  DEACTIVE_APKV = '[APKV] Deactive APKV',
  DEACTIVE_APKV_SUCCESS = '[APKV] Deactive APKV Success',
  DEACTIVE_APKV_FAILED = '[APKV] Deactive APKV Failed',
  INIT_NAMINGPROCESS = '[NAMING_PROCESS] Init Namingprocess',
  SET_NEXT_NAVIGATION_INDEX = '[NAMING_PROCESS] Set next navigation index',
  SET_PREVIOUS_NAVIGATION_INDEX = '[NAMING_PROCESS] Set previous navigation index',
  RESET_NAVIGATION_INDEX = '[NAMING_PROCESS] Reset navigation index',
  SET_NAVIGATION_INDEX = '[NAMING_PROCESS] Set navigation index',
  SET_NAMINGPROCESS_APKV_DATA = '[NAMING_PROCESS] Set Namingprocess APKV Data',
  CHECK_APKV_EMAILS_IN_USER_ACCOUNT = '[NAMING_PROCESS] Check APKV Emails In User Account',
  CHECK_APKV_EMAILS_IN_USER_ACCOUNT_SUCCESS = '[NAMING_PROCESS] Check APKV Emails In User Account Success',
  CHECK_APKV_EMAILS_IN_USER_ACCOUNT_FAILED = '[NAMING_PROCESS] Check APKV Emails In User Account Failed',
  RESET_ERROR_OBJECT = '[NAMING_PROCESS] Reset Error Object',
  DOWNLOAD_PDF = '[NAMING_PROCESS] Download PDF',
  DOWNLOAD_PDF_SUCCESS = '[NAMING_PROCESS] Download PDF Success',
  DOWNLOAD_PDF_FAILED = '[NAMING_PROCESS] Download PDF Failed',
  CREATE_APKV = '[NAMING_PROCESS] Create APKV',
  CREATE_APKV_SUCCESS = '[NAMING_PROCESS] Create APKV Success',
  CREATE_APKV_FAILED = '[NAMING_PROCESS] Create APKV Failed',
  INIT_STORE = '[CHECK_CODE] Init Store',
  REFRESH_SESSION = '[CHECK_SESSION] Refresh Session',
  REFRESH_SESSION_SUCCESS = '[CHECK_SESSION] Refresh Session Success',
  REFRESH_SESSION_FAILED = '[CHECK_SESSION] Refresh Session Failed',
  DESTROY_SESSION = '[DESTROY_SESSION] Destroy Session',
  DESTROY_SESSION_SUCCESS = '[DESTROY_SESSION] Destroy Session Success',
  DESTROY_SESSION_FAILED = '[DESTROY_SESSION] Destroy Session Failed',
}

export const downloadPdf = createAction(
  apkvActionTypes.DOWNLOAD_PDF,
  props<{ pdfType: PdfTypesEnum; apkv?: ApkvPersonModel }>(),
);

export const downloadPdfError = createAction(
  apkvActionTypes.DOWNLOAD_PDF_FAILED,
  props<{ error; pdfType: PdfTypesEnum }>(),
);

export const downloadPdfSuccess = createAction(
  apkvActionTypes.DOWNLOAD_PDF_SUCCESS,
  props<{ pdfType: PdfTypesEnum }>(),
);

export const checkCode = createAction(
  apkvActionTypes.CHECK_CODE,
  props<{ zugangscode: string; pin: string }>(),
);

export const checkCodeError = createAction(
  apkvActionTypes.CHECK_CODE_FAILED,
  props<{ error; codeValidityObject }>(),
);

export const checkCodeSuccess = createAction(
  apkvActionTypes.CHECK_CODE_SUCCESS,
  props<{ sessionId: string; zugangscode: string }>(),
);

export const retrieveApkvList = createAction(
  apkvActionTypes.RETRIEVE_APKVS,
  props<{ sessionId: string }>(),
);

export const retrieveApkvListError = createAction(
  apkvActionTypes.RETRIEVE_APKVS_FAILED,
  props<{ error }>(),
);

export const retrieveApkvListSuccess = createAction(
  apkvActionTypes.RETRIEVE_APKVS_SUCCESS,
  props<{ ekhData: ApkvListResponse }>(),
);

export const setActiveApkv = createAction(
  apkvActionTypes.SET_ACTIVE_APKV,
  props<{ apkv: Partial<ApkvPersonModel> }>(),
);

export const resetAtiveApkv = createAction(apkvActionTypes.RESET_ACTIVE_APKV);

export const deactiveApkv = createAction(apkvActionTypes.DEACTIVE_APKV);

export const deactiveApkvError = createAction(
  apkvActionTypes.DEACTIVE_APKV_FAILED,
  props<{ error }>(),
);

export const deactiveApkvSuccess = createAction(
  apkvActionTypes.DEACTIVE_APKV_SUCCESS,
);

export const initNamingProcess = createAction(
  apkvActionTypes.INIT_NAMINGPROCESS,
);

export const setNavigationIndex = createAction(
  apkvActionTypes.SET_NAVIGATION_INDEX,
  props<{ index: number }>(),
);

export const setNextNavigationIndex = createAction(
  apkvActionTypes.SET_NEXT_NAVIGATION_INDEX,
);

export const setPreviousNavigationIndex = createAction(
  apkvActionTypes.SET_PREVIOUS_NAVIGATION_INDEX,
);

export const resetNavigationIndex = createAction(
  apkvActionTypes.RESET_NAVIGATION_INDEX,
);

export const setNamingProcessApkvData = createAction(
  apkvActionTypes.SET_NAMINGPROCESS_APKV_DATA,
  props<{ apkvData: PeronFormApkvObject }>(),
);

export const checkApkvEmailsInUserAccount = createAction(
  apkvActionTypes.CHECK_APKV_EMAILS_IN_USER_ACCOUNT,
  props<{ personObject: PersonFormObject; apkvObject }>(),
);

export const checkApkvEmailsInUserAccountError = createAction(
  apkvActionTypes.CHECK_APKV_EMAILS_IN_USER_ACCOUNT_FAILED,
  props<{ error }>(),
);

export const checkApkvEmailsInUserAccountSuccess = createAction(
  apkvActionTypes.CHECK_APKV_EMAILS_IN_USER_ACCOUNT_SUCCESS,
  props<{ personenFormValidationObject: PersonFormObject }>(),
);

export const resetCodeValidityObject = createAction(
  apkvActionTypes.RESET_CODE_VALIDITY_OBJECT,
);

export const setCodeValidityObject = createAction(
  apkvActionTypes.SET_CODE_VALIDITY_OBJECT,
  props<{ codeIsInvalid: string; pinIsInvalid: string }>(),
);

export const createApkv = createAction(apkvActionTypes.CREATE_APKV);

export const createApkvError = createAction(
  apkvActionTypes.CREATE_APKV_FAILED,
  props<{ error }>(),
);

export const createApkvSuccess = createAction(
  apkvActionTypes.CREATE_APKV_SUCCESS,
  props<{ apkvData: ApkvPersonModel[] }>(),
);

export const initStore = createAction(apkvActionTypes.INIT_STORE);

export const refreshSession = createAction(apkvActionTypes.REFRESH_SESSION);

export const destroySession = createAction(apkvActionTypes.DESTROY_SESSION);
