import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { initStore, refreshSession } from '../../overview/+store/apkv.actions';

@Injectable({
  providedIn: 'root',
})
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.store.dispatch(refreshSession());
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          shouldRedirectToErrorPage(error.status) &&
          !error.url.includes('verifiziere')
        ) {
          this.store.dispatch(initStore());
          this.router.navigate(['/fehler']);
        }
        return throwError(error.message);
      }),
    );
  }
}

const shouldRedirectToErrorPage = (statusCode: number): boolean =>
  statusCode !== 400 &&
  statusCode !== 403 &&
  statusCode !== 404 &&
  statusCode !== 409;
