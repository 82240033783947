<div
  class='status-item'
  [ngClass]='stateList[state]?.color'
  *ngIf='!isArray(stateList[state])'
>
  {{stateList[state]?.label}}
</div>
<div class='status-container' *ngIf='isArray(stateList[state])'>
  <div class='status-item' [ngClass]='stateItem.color' *ngFor='let stateItem of stateList[state]'>

    {{stateItem.label}}

  </div>
</div>
