import { Routes } from '@angular/router';
import {
  ApkvBestaetigungDeaktivierungComponent,
  ApkvThankYouComponent,
  ApkvNamingProcessComponent,
  StartComponent,
  ApkvUebersichtComponent,
} from './components';

export const APKV_ROUTES: Routes = [
  { path: '', redirectTo: 'check-code', pathMatch: 'full' },
  {
    path: 'check-code',
    component: StartComponent,
    pathMatch: 'full',
    title: 'Anmeldung',
  },
  {
    path: 'naming',
    component: ApkvNamingProcessComponent,
    pathMatch: 'full',
    title: 'Verantwortliche Personen Benennen',
  },
  {
    path: 'thank-you',
    component: ApkvThankYouComponent,
    pathMatch: 'full',
    title: 'Vielen Dank',
  },
  {
    path: 'overview',
    component: ApkvUebersichtComponent,
    pathMatch: 'full',
    title: 'Verwaltung verantwortliche Personen',
  },
  {
    path: 'overview/deactivate',
    component: ApkvBestaetigungDeaktivierungComponent,
    pathMatch: 'full',
    title: 'Deaktivieren',
  },
  // { path: 'overview/change', component: ApkvBestaetigungWechselComponent, pathMatch: 'full' },
  { path: '**', redirectTo: 'check-code' },
];
