<div class='logged-out-wrapper'>
    <div class='logged-out-container'>
        <div class='logged-out-content'>
            <div class='flexed-logged-out-content'>
            <span>
                <img
                    class='logout-logo-image'
                    alt=''
                    id="logout-logo-id"
                    src='assets/images/logos/Cleaning_PC_Isometric.svg'>
            </span>
            <h1>Sie wurden abgemeldet</h1>
            <div>
                <p>
                    Ihre
                    <strong>Sitzung</strong>
                    wurde
                    <strong>beendet</strong>.
                    Sie können zur Startseite zurückkehren und sich erneut mit Ihrem Zugangscode anmelden.
                </p>
            </div>
            <div class='logged-out-button-container'>
                <ogr-button
                    buttonId='logout-button'
                    link='/apkv/check-in'>
                    Zur Anmeldeseite
                </ogr-button>
            </div>
            </div>
        </div>
    </div>
</div>



