import { HttpParams } from '@angular/common/http';

export class FormatHelperService {
  public getFormValueObject(form): any {
    return Object.keys(form.value).reduce((acc, key) => {
      if (form.value[key] !== null) {
        acc = { ...acc, [key]: form.value[key] };
      }
      return acc;
    }, {});
  }

  public generateHttpParams(paramsObject): HttpParams {
    let params = new HttpParams();
    if (!paramsObject || Object.keys(paramsObject).length === 0) {
      return params;
    }
    for (const key of Object.keys(paramsObject)) {
      params = params.set(key, paramsObject[key]);
    }
    return params;
  }

  public getLabelByKey(stringObject: {}, key: string): string {
    if (!stringObject || Object.keys(stringObject)?.length === 0 || !key) {
      return '-';
    }
    if (!Object.keys(stringObject).includes(key)) {
      return 'Wert ist nicht enthalten.';
    }
    return stringObject[key];
  }

  public getQueryStringByObj(query): string {
    return Object.keys(query).reduce((acc, field) => {
      return `${acc}${field}=${query[field]}&`;
    }, '?');
  }
}
