<div class='infopage-container'>
  <div class='infopage-content content-layout'>
    <div class='top-bar-content btn-margin-button' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left'>
        <span>Zurück</span>
      </ogr-button>
    </div>
    <h1>Barrierefreiheit</h1>
    <p>
      Diese Erklärung zur Barrierefreiheit gilt für die Internetseite apkv.organspende-register.de.
    </p>
    <div class='infopage-content-block'>
      <h2>Barrierefreiheit dieser Internetseite</h2>
      <p>
        Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 ist das Bundesinstitut für Arzneimittel und
        Medizinprodukte (BfArM) bemüht,
        die Internetseite apkv.organspende-register.de barrierefrei zugänglich zu machen.
        Rechtsgrundlage sind das Behindertengleichstellungsgesetz (BGG) und die Barrierefreie
        Informationstechnik-Verordnung (BITV 2.0)
        zur Umsetzung der Richtlinie (EU) 2016/2102 in ihrer jeweils gültigen Fassung.
      </p>
    </div>

    <div class='infopage-content-block infopage-orange-ul'>
      <h2>Stand der Vereinbarkeit mit den Anforderungen</h2>
      <p>
        Die Anforderungen der Barrierefreiheit ergeben sich aus §§ 3 Absätze 1 bis 4 und 4 der BITV 2.0, die auf der
        Grundlage von § 12d BGG erlassen wurde.
      </p>
      <p>
        Die Überprüfung der Einhaltung der Anforderungen beruht auf einer von den Sozialhelden vom 28.08.2023 bis 31.08.2023
        vorgenommenen Bewertung.
      </p>
      <p>
        Aufgrund der Überprüfung ist die Internetseite apkv.organspende-register.de
        mit den zuvor genannten Anforderungen wegen der folgenden Ausnahmen teilweise vereinbar
      </p>
      <p>
        Die nachstehend aufgeführten Inhalte sind aus folgenden Gründen nicht barrierefrei
      </p>
      <ul>
        <li>
          <span aria-hidden="true" class="marker"></span>Einige Inhalte brechen bei Vergrößerung oder kleinen
          Bildschirmauflösungen nicht korrekt um oder werden verdeckt.
        </li>
        <li><span aria-hidden="true" class="marker"></span>Aus Sicherheitsgründen kann es in manchen Situationen
          vorkommen, dass Fehlermeldungen ungenau oder unpräzise sind und Nutzenden nicht erklären,
          was das Problem ist und wie das Problem behoben werden kann.
        </li>
        <li><span aria-hidden="true" class="marker"></span>Die Erläuterungen der wesentlichen Inhalte der Internetseite
          in Leichter Sprache und in Deutscher Gebärdensprache fehlen.
        </li>
      </ul>
      <p>
        Die Barrierefreiheit ist Bestandteil des gegenwärtigen Entwicklungsprozesses. Wir arbeiten daran,
        die noch vorhandenen Barrieren zu beheben.
      </p>
      <p>Diese Erklärung wurde am 06.11.2023 aktualisiert.</p>
    </div>

    <div class='infopage-content-block'>
      <h2>Feedback und Kontaktangaben</h2>
      <p>
        Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten von apkv.organspende-register.de aufgefallen?
        Oder haben Sie Fragen zum Thema Barrierefreiheit? Dann können Sie sich gerne bei uns melden.
        Schreiben Sie uns dazu eine E-Mail an:
        <a href='mailto:kontakt@organspende-register.de' rel='noopener'
           target='_blank'>kontakt&#64;organspende-register.de</a>
      </p>
      <p>
        Sie können uns auch per Post kontaktieren: Das Bundesinstitut für Arzneimittel und Medizinprodukte (BfArM),
        Fachgebiet K6 – Organspende-Register, Kurt-Georg-Kiesinger-Allee 3, 53175 Bonn
      </p>
    </div>

    <div class='infopage-content-block'>
      <h2>Schlichtungsverfahren</h2>
      <p>
        Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde,
        können Sie sich an die Schlichtungsstelle nach § 16 BGG wenden.
        Die Schlichtungsstelle BGG hat die Aufgabe, bei Konflikten zum Thema Barrierefreiheit
        zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes eine außergerichtliche Streitbeilegung
        zu unterstützen.
        Das Schlichtungsverfahren ist kostenlos.
        Es muss kein Rechtsbeistand eingeschaltet werden. Weitere Informationen
        zum Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter
        <a class="link-extern" href='https://www.schlichtungsstelle-bgg.de' rel='noopener' target='_blank'>www.schlichtungsstelle-bgg.de
          <ogr-icon icon="linkextern" width="14" height="14" color="info"></ogr-icon>
        </a>
      </p>
      <p>
        Direkt kontaktieren können Sie die Schlichtungsstelle BGG unter
        <a href='mailto:info@schlichtungsstelle-bgg.de' rel='noopener'
           target='_blank'>info&#64;schlichtungsstelle-bgg.de</a>.
      </p>
    </div>
    <div class='top-bar-content' *ngIf='!loggedIn'>
      <ogr-button (buttonClick)='goBackToForm()' color='text' iconColor='primary' icon='left'>
        <span>Zurück</span>
      </ogr-button>
    </div>
  </div>
</div>
