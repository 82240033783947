import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ApkvService {
  apkvFormState: UntypedFormGroup;
  url: string;
  backLinksAvailableOn = [
    '/hilfe',
    '/impressum',
    '/datenschutz',
    '/barrierefreiheit',
    '/rechtliche-hinweise',
  ];
  public getApkvDataArrayByObject(apkvObject): any {
    if (!apkvObject) {
      return [];
    }
    return Object.keys(apkvObject).reduce((acc, curr) => {
      return [...acc, apkvObject[curr]];
    }, []);
  }

  public getDeactivateApkvNames(checkedListElements: any): [string] {
    return checkedListElements.map((item) => {
      return `${item.titel ? item.titel + ' ' : ''}${item.vorname} ${
        item.nachname
      }`;
    });
  }
}
